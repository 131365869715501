﻿.consultant-search-form {

    &--wrapper {
        background-color: $spire-light-blue;
        border: solid 1px $spire-blue;
        padding: 20px;

        > p {
            margin-bottom: 0px;
        }

        & h2 {
            color: $spire-blue;
            font-size: 28px;
        }
    }

    & .search-button-position {
        @media (min-width: $desktop-min) {
            margin-top: 38px;
        }
    }

}
