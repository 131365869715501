﻿.customerenquiryform {

    padding-top: 40px;
    padding-bottom: 40px;

    &__contact-us {
        font-size: 17px;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    h2 {
        > .first-word {
            font-family: 'Lato-Light';
            display: inline-block;
            border-bottom: solid 1px $spire-charcoal;
            padding-bottom: 6px;
        }

        margin-bottom: 45px;
    }


    &--top-spacer {
        //padding-top: 20px;
        @include media-breakpoint-up(lg) {
            padding-top: 0px;
        }
    }

    &.background--mint {

        @include media-breakpoint-up(md) {
            padding-top: 0;
        }
    }

    .display-1 {
        color: $spire-charcoal;
    }
}

.section-enquiry-form {
    background: $spire-mint;
}

.enquiry-form {
    pointer-events: auto;
    //margin-bottom: 40px;
    @media (max-width: $tablet-max) {
        overflow: hidden;
    }

    @media (min-width: $desktop-min) {
        // margin-bottom: 70px;
    }

    &--seperator {
        border-top: solid 1px $spire-blue;
        padding-top: 40px;
    }

    &__header {

        &__intro {
            margin-bottom: 30px;

            h1 {
                font-size: 32px;
                font-weight: 300;
            }
        }

        .tabs__nav {
            max-width: 600px;

            a {
                font-weight: 500;
                font-size: 18px;

                @media (max-width: $tablet-max) {
                    padding: 10px 65px;
                }

                @media (max-width: 550px) {
                    padding: 10px 50px;
                }

                @media (max-width: 420px) {
                    padding: 10px 20px;
                }

                &:after {
                    vertical-align: bottom;
                    margin-left: 15px;
                }

                &.is-on {
                    font-weight: 300;
                }

                &.enquiry-form__tabs__link--callback {

                    &:after {
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    &__contact {
        color: #fff;

        a {
            color: #fff;
        }

        &__heading {
            padding: 0 80px 0 0;
        }
    }

    &__tabs {
        margin: 0 -10px;

        .body--hospital & {
            padding-top: 35px;
        }

        ul {
            margin: 0;
            padding: 0;
        }

        li {
            display: inline-block;
            margin: 0;
            max-width: 50%;
        }

        &__link {
            max-width: 100%;
            width: 200px;
            box-sizing: border-box;
            padding: 10px 40px 10px 20px;
            display: block;
            background: $lightgrey;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            position: relative;

            &:hover {
                color: #fff;
                background: $lightergrey;
            }

            &.is-on {
                color: $lightgrey;
                background: #fff;
            }

            &:after {
                position: absolute;
                right: 20px;
                top: 50%;
                font-size: 20px;
                margin: -10px 0 0 0;
            }

            &--callback {
                @include iconfont(phone, after);
            }

            &--enquiry {
                @include iconfont(email, after);
            }
        }

        &__content {
            display: none;

            @media (max-width: $tablet-max) {
                padding-top: 15px;
            }

            &.is-on {
                display: block;
            }

            .input-text, .select-wrapper__select, textarea {
                font-family: $font-family-lato-light;
                border: 1px solid $spire-blue;
                box-sizing: border-box;
                width: 100%;
                border-radius: 4px;
                -webkit-appearance: none;

                &:focus {
                    border: 1px solid $spire-dark-blue;
                    outline: 0;
                }
            }

            .inner-area {

                @media (min-width: $desktop-min) {
                    padding-top: 30px;
                }
            }

            .input-text {
                height: 59px;
                padding-left: 30px;
            }

            .select-wrapper {
                &__select {
                    padding: 18px 0 15px 8px;

                    @media (min-width: $desktop-min) {
                        padding: 18px 0 15px 25px;
                    }

                    > option:hover {
                        background-color: $spire-blue !important;
                        outline: 0;
                    }

                    &:focus {
                        outline: 0;
                    }
                }
            }



            &__inner {
                // background: #fff;
                // padding: 10px 0;
                padding: 0;
                margin: 0 15px;

                @media (min-width: $desktop-min) {
                    position: relative;
                    margin: 0 auto;
                }

                @media (min-width: $tablet-max) {
                    // padding-top: 35px;
                }

                &__container {
                    max-width: 700px;
                    margin: 0 auto;

                    &__note {
                        color: #505254;
                        margin: 0 20px 0 10px;
                        height: 64px;

                        @media (min-width: $desktop-min) {
                            margin-bottom: 20px;
                        }
                    }

                    &__element {
                        margin: 0 0px 20px 0px;
                        position: relative;

                        .textarea--counter--error {
                            color: $spire-error;
                        }

                        &__clear {
                            clear: both;
                        }

                        &__checkbox-list {
                            div {
                                float: left;
                                width: 80px;
                            }

                            &__no-float {

                                div {
                                    clear: both;
                                    width: 100%;
                                }
                            }
                        }

                        &--textarea {
                            &--counter {
                                padding: 5px 0 0 0;
                                color: #b4b4b4;
                                display: block;
                                width: auto;
                                text-align: right;
                            }
                        }

                        &--key {
                            margin-bottom: 0;

                            &__text {
                                color: $spire-error;
                                text-align: right;
                                @include font-size-rem(12px);
                            }
                        }

                        &--dob {

                            @media (min-width: $desktop-min) {
                                width: 100%;
                            }

                            .select-wrapper--dob {
                                display: inline-block;
                                width: 32%;

                                @media (min-width: $desktop-min) {
                                    width: auto;
                                }

                                .select-wrapper__select {
                                    @media (min-width: $desktop-min) {
                                        padding-right: 45px;
                                    }
                                }
                            }

                            .parsley-errors-list {
                                display: inline;
                                margin-right: 5px;
                            }
                        }

                        &--tcs {
                            margin-bottom: 10px;

                            .input-checkbox-wrapper {
                                display: block;
                                position: relative;
                                padding-left: 35px;
                                margin-bottom: 12px;
                                cursor: pointer;
                                font-size: 16px;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                            }

                            .input-checkbox-wrapper input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                            }
                            /* Create a custom checkbox */
                            .checkmark {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 24px;
                                width: 24px;
                                background-color: #fff;
                                border-radius: 4px;
                                border: solid 1px $spire-blue;
                            }
                            /* On mouse-over, add a grey background color */
                            .input-checkbox-wrapper:hover input ~ .checkmark {
                                background-color: #fff;
                            }
                            /* When the checkbox is checked, add a blue background */
                            .input-checkbox-wrapper input:checked ~ .checkmark {
                                background-color: $spire-green;
                            }
                            /* Create the checkmark/indicator (hidden when not checked) */
                            .checkmark:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                            /* Show the checkmark when checked */
                            .input-checkbox-wrapper input:checked ~ .checkmark:after {
                                display: block;
                            }
                            /* Style the checkmark/indicator */
                            .input-checkbox-wrapper .checkmark:after {
                                left: 9px;
                                top: 5px;
                                width: 5px;
                                height: 10px;
                                border: solid white;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }


                            .input-checkbox {
                                /*margin: 3px 7px 0 0;*/
                            }
                            /*input[type=checkbox].input-checkbox {
                                position: absolute;
                                z-index: -1000;
                                left: -1000px;
                                overflow: hidden;
                                clip: rect(0 0 0 0);
                                height: 1px;
                                width: 1px;
                                margin: -1px;
                                padding: 0;
                                border: 0;
                            }

                            input[type=checkbox].input-checkbox + label.css-label {
                                font-family: 'Lato-Regular';
                                padding-left: 35px;
                                height: 30px;
                                display: inline-block;
                                line-height: 30px;
                                background-repeat: no-repeat;
                                background-position: 0 0;
                                font-size: 14px;
                                vertical-align: middle;
                                cursor: pointer;
                            }

                            input[type=checkbox].input-checkbox:checked + label.css-label {
                                background-position: 0 -30px;
                            }

                            label.css-label {
                                background-image: url(http://csscheckbox.com/checkboxes/u/csscheckbox_f60067e68146be412873f96f1d2458cd.png);
                                -webkit-touch-callout: none;
                                -webkit-user-select: none;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                            }*/
                        }

                        &__checkbox {
                            float: left;
                            width: auto;
                            margin: 5px 5px 0 0;
                        }

                        &__checkbox-label {
                            color: #505254;
                            overflow: hidden;
                            display: block;
                        }

                        &__checkbox-label-bold {
                            color: $darkgrey !important;
                            overflow: hidden;
                            display: block;
                            font-weight: bold;
                        }

                        &__checkbox__email {
                            float: left;
                            width: auto;
                            margin: 5px 5px 0 0;
                        }

                        &__checkbox__sms {
                            float: left;
                            width: auto;
                            margin: 5px 5px 0 0;
                        }

                        &__checkbox__post {
                            float: left;
                            width: auto;
                            margin: 5px 5px 0 0;
                        }

                        &__checkbox__educationevents {
                            float: left;
                            width: auto;
                            margin: 5px 5px 0 0;
                        }

                        &__checkbox__privacy {
                            float: left;
                            width: auto;
                            margin: 5px 5px 0 0;
                        }

                        textarea {
                            height: 200px !important;
                        }

                        label {
                            font-family: 'Lato-Regular';
                            display: block;
                            color: $spire-charcoal;
                            font-size: 16px;
                            line-height: 19px;
                            margin-bottom: 11px;

                            span {
                                color: $spire-error;
                                margin-left: 3px;
                            }
                        }

                        .limited-width {
                            max-width: 260px;
                        }

                        &__label-bold {
                            display: block;
                            color: $darkgrey !important;
                            font-size: 13px;
                            margin-bottom: 5px;
                            font-weight: bold;

                            span {
                                color: $spire-error;
                                margin-left: 3px;
                            }
                        }

                        .error {
                            padding: 5px 0 0 0;
                            @include font-size-rem(12px);
                            color: $spire-error;
                        }

                        &.parsley-error, &--error {
                            color: $spire-error;

                            .input-text, .select-wrapper__select, textarea {
                                border-color: $spire-error;
                            }
                        }

                        .parsley-errors-list {
                            li {
                                max-width: 100%;
                                color: $spire-error;
                            }
                        }
                    }

                    &__divider {
                        margin: 5px 10px 25px 10px;
                    }
                }
            }
        }
    }

    &__thankyou {

        &__content {
            max-width: 500px;
            margin: 0 auto;
            padding: 0;

            &__redirect {
                padding-top: 20px;

                p {
                    font-weight: bold;
                    margin-bottom: 0;
                }

                .enquiry-form__close {
                    position: relative;
                    left: 0;
                    right: 0;
                    top: 0;
                    display: inline;
                    float: left;
                    margin: -2px 20px 0 0;

                    &:after {
                        margin-left: 7px;
                    }
                }
            }
        }
    }

    &__hidden-field {
        display: none;
    }

    &__button-container {
        @media (max-width: $tablet-max) {
            text-align: center;
            margin: 0 10px;
        }

        &__spinner {
            display: none;
            vertical-align: middle;
            margin-top: 15px;
            width: 60px;

            @media (min-width: $desktop-min) {
                margin-top: -5px;
            }

            &--on {
                display: inline-block;
            }
        }
    }

    &__submit {
        font-size: 16px;
        padding: 14px 25px;
        margin: 0 auto;

        @media (max-width: $tablet-max) {
            width: 100%;
        }

        @media (min-width: $desktop-min) {
            margin: 0 10px 20px 10px;
        }
    }

    .js-date-required-concatenate {
        padding: 5px 0 0 0;
        font-size: 12px;
        font-size: 0.85714rem;
        color: $spire-error
    }

    .parsley-custom-error-message, .parsley-required {
        max-width: 100%;
    }

    .field-inner {
        position: relative;

        .field-inner--error {
            display: none;
            position: absolute;
            right: 22px;
            top: 22px;
            color: #fff;
            width: 22px;
            @include iconfont(exclamation, before, 'FontAwesome');
            text-align: center;
            background: $spire-error;
            border-radius: 12px;
        }
    }

    .parsley-error {
        .field-inner--error {
            display: block;
        }

        .select-wrapper__select:focus, .js-enquiry-textarea:focus {
            //  outline: 0;
        }
    }

    .enquiry-form__tabs__content__inner__container__element--error {
        .select-wrapper__select:focus {
            // outline: 0;
        }
    }

    .label--weight-normal {
        font-weight: normal;
        color: $spire-charcoal;
        font-family: 'TheSans Spire Regular';
    }
}

.thank-you-enquiry {
    margin-bottom: 40px;

    .thank-you__content {
        padding: 0 5%;

        .enquiry-form__button-container {
            margin: 0;
        }

        p {
            margin: 0;
        }
    }

    .hospital-name {
        color: #3291a5;
    }
}

.inpage-enquiry-form {

    &--wrapper {
        // background-color: $spire-mint;
    }

    ul {
        margin: 0px;
        padding-left: 2px;
    }

    li {
        display: inline-block;
        margin: 0;
        max-width: 50%;
    }

    .select-wrapper--dob select {
        @media (min-width: $desktop-min) {
            padding-right: 25px !important;
        }
    }

    &__tabs__content__inner {
        margin-left: 0px;
        margin-right: 0px;
        background-color: $spire-mint;

        @media (max-width: $tablet-max) {
            // padding-top: 15px;
        }
    }

    &__heading {
        padding: 0 20px;

        .x-large {
            color: $spire-grey;
            margin: 0 0 36px 0;

            @media (max-width: $tablet-max) {
                margin: 0 0 20px 0;
            }
        }

        .accordion__header {
            cursor: default;
            padding-bottom: 20px;
        }
    }

    &__content {

        &__vertical_divider {
            border-right: solid 1px #46b4cd;
            margin-bottom: 20px;
            border: none;
        }

        .enquiry-form__tabs__content__inner__container {
            max-width: none;
            padding: 0;
        }

        .js-enquiry-form__tabs__content__inner__container__element-select-hospital {
            margin-bottom: 0px;
        }
    }

    &__thankyou {

        &__tabs {
            padding-top: 0px;

            .tabs__content {
                padding-top: 0px;
            }
        }
    }
}

.consultantInpageEnquiryForm {
    margin-left: -15px;
    margin-right: -15px;
    //margin-bottom: -60px;
    .inpage-enquiry-form {

        &__tabs__content__inner {
            padding-bottom: 0px;
        }
    }
}

.consultantInpageEnquiryForm {
    .inpage-enquiry-form {
        .select-wrapper__select {
            font-size: 14px;
        }
    }
}
