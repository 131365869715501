﻿.tabBanner {
    &--wrapper {
        background-color: $spire-header-blue;
        margin-top: 0 !important;
        padding-top: 15px;
        padding-bottom: 17px;
    }

    color: #fff;

    & a {
        color: #fff;
    }

    &--bar {
        border-left: solid 1px #fff;
    }
}