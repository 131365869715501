﻿//body {
//}

//Layout
$desktop-min: 992px;
$tablet-max: $desktop-min - 1px;
$tablet-min: 600px;
$mobile-max: $tablet-min - 1px;

//Typography
$typeBase: 14px;
$offwhite: #f0f3f6;
$white: #fff !default;

// Phase2 Colours
// Primary
$spire-header-blue: #469CAF !default; // Header Bars
$spire-header-line: #A4CED8 !default; // Mobile menu line separators
$spire-border-line: #74B5C3 !default; // navigaton borders
$spire-blue: #18839B !default; // Menu Font header colours // H2s 
$spire-light-blue: #E7F2F5 !default; // Menu Backgrounds
$spire-medium-blue: #B9DAE1 !default; // Quotes
$spire-dark-blue: #13748A !default;
$spire-body-grey: #636363 !default; // Body Font Colour
$spire-body-light-grey: #EBEBEB !default; // Bars
$spire-charcoal: #333333 !default;   // Font Colours slightly darker than body for Intros

// Old
$spire-green: #008385 !default;
$spire-teal: #00A4A6 !default;
$spire-mint: #F0FAFA !default;

// Secondary
$spire-turquoise: #16CCBB !default;
$spire-light-turquoise: #16CCBB !default;
$spire-grey: #4A4A4A !default;
// Tertiary
$spire-smoke: #F9F9F9 !default;
$spire-stone: #d0f4f0 !default;
$spire-error: #E52121 !default;
// Quaternary
$spire-green-tint: rgba(22,204,187,0.51) !default;
$spire-dark-grey: #414246 !default;
$spire-light-charcoal: #6D6E71 !default;
$spire-mid-grey: #CFCFCF !default;
$spire-light-grey: #eeeeee !default;
$spire-grey-mega-menu: #626262;
$spire-lightmagenta: #d73282 !default;
$spire-darkmagenta: #be236e !default;
$spire-alt-grey: #CFCFCF !default;

/* component settings */
$component-padding--top: 15px;
$component-padding--bottom: 15px;
$component-padding--desktop-top: 22px;
$component-padding--desktop-bottom: 22px;
$component-padding--desktop-both: 44px;

/* module settings */
$module-padding: 35px 0 35px 0;
$module-padding--top: 35px;
$module-padding--bottom: 35px;
$module-padding--mobile: 11px 0 35px 0;

$sticknav-desktop-height: 62px;

// Text colours, mid-grey for body copy, dark grey for headings/sub-headings
$midgrey: #6f7175;
$darkgrey: #46484b;
$lightgrey: #b4b4b4;
$lightergrey: #bebebe; //hover state
$bluegrey: #f4f9fc;


// form field seetings
$inputPadding: 30px;
$field-padding: 20px 8px;
$field-finder-padding: 8px 8px;
/*  Brand Themes: 
    Variables set by picking colours 
    starting from the top of PSD - i.e.
    $perform-blue1, $perform-blue2 and so on
*/
//Treatment areas - CS
$cs-brown1: #e0b9a4;
$cs-brown2: #bd7e5f;
$cs-brown3: #8c5438;
$cs-light-brown: #e1a582;

//used for borders and hr
$straw: #fcf5e9;
$cs-red1: #ff3b66;

/*  Treatment areas - 
  Perform + St George share the same colours */
$perform-blue1: #4895cc;
$perform-blue2: #4e6e89;
$perform-blue3: #37546c;
$perform-red1: #ff3b66;

//Treatment areas - Lifescan
$lifescan-blue1: #003870;
$lifescan-red1: #ff3b66;
$lifescan-red2: #CD2850;
$lifescan-purple: #6E8CB4;

//Montefiore
$montefiore-grey1: #5a5b5f;
$montefiore-grey2: #66696f;
$montefiore-grey3: #75787e;
$montefiore-grey4: #75787e;
$montefiore-grey5: #b4b4b4;
$montefiore-grey6: #808388;
$montefiore-red1: #b71a6d;
//$montefiore-red2: #c72878;
$montefiore-red2: #e13a8d;
$montefiore-green1: #9dbb33;
$montefiore-blue1: #1a99ba;

/* font-awesome characters */
/* TODO these are all defined in spire.umbraco\src\scssp2\_plugins\_fontawesome5\_variables.scss   */
/* do we need to just import them ? */
$long-arrow-left: '\f177';
$long-arrow-right: '\f178';
$plus-sign: '\f067';
$minus-sign: '\f068';