﻿.login-registration-form {
    & h2 {
        color: $spire-blue;
    }
    /* & h3 {
        color: #18839B;
        font-size: 20px;
        font-weight: bold;
    }*/

    .radio-wrapper {
        display: inline-flex;
        // align-items: center;
        cursor: pointer;
        margin-right: 10px;
    }

    .radiomark {
        width: 1.25em;
        height: 1.25em;
        border: 1px solid #18839B;
        border-radius: 50%;
        margin-right: 10px;
        box-sizing: border-box;
        background: #E7F2F5;
        padding: 2px;
    }

    .radiomark::after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: #18839b;
        border-radius: 50%;
        transform: scale(0);
    }

    .radio-input {
        display: none;
    }

    .radio-input:checked + .radiomark::after {
        transform: scale(1);
    }


    .input-text, .select-wrapper__select, textarea {
        font-family: $font-family-lato-light;
        border: 1px solid $spire-blue;
        box-sizing: border-box;
        width: 100%;
        border-radius: 4px;
        -webkit-appearance: none;

        &:focus {
            border: 1px solid $spire-dark-blue;
            outline: 0;
        }
    }

    .row-spacing {
        margin-bottom: 36px;
    }

    .label-text {
        font-size: 16px;
        color: #2B313E;
        font-weight: bold;
    }

    .header {
        font-family: "Lato-Light";
        font-size: 20px;
        color: #2B313E;
        margin-bottom: 20px;

        &.sub {
            font-family: "Lato-Regular";
            color: #18839B !important;
        }
    }


    & p {
        font-family: "Lato-Light" !important;
        color: black;
    }

    .confirmation-message {
        font-family: "Lato-Regular" !important;
        color: #2B313E !important;
        font-weight: bold !important;
    }

    & hr {
        border: solid 1px #18839b;
        margin-bottom: 30px;
        opacity:0.5;
    }

    .inner-area {

        @media (min-width: $desktop-min) {
            padding-top: 30px;
        }
    }

    .input-text {
        height: 52px;
        padding-left: 30px;
    }

    .select-wrapper {
        width: 100%;
        height: 52px;

        &__select {
            padding: 18px 0 15px 8px;

            @media (min-width: $desktop-min) {
                padding: 18px 0 15px 25px;
            }

            > option:hover {
                background-color: $spire-blue !important;
                outline: 0;
            }

            &:focus {
                outline: 0;
            }
        }
    }

    & .error {
        border-color: #e52121;
    }

    .field-inner {
        position: relative;

        .field-inner--error {
            display: none;
            position: absolute;
            right: 22px;
            top: 22px;
            color: #fff;
            width: 22px;
            @include iconfont(exclamation, before, 'FontAwesome');
            text-align: center;
            background: $spire-error;
            border-radius: 12px;
        }

        & .error-message {
            display: none;
            font-family: "lato-regular";
            color: $spire-error;

            &-compare {
                display: none;
            }
        }
    }

    & .login-box {
        border: 1px solid $spire-blue;
        background-color: $spire-light-blue;
        margin: 20px;
        padding: 30px;
        min-height: 470px;

        & .form-field {
            padding-top: 20px;
        }
    }

    & .button--rounded-blue-transparent:after {
        content: none;
        padding: 0px;
    }
}
