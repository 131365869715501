﻿
.conditions-consultant {
    & h2 {
        color: $spire-blue;
        font-size: 28px;
    }

    .conditions-consultant-list {
        $font-awesome: "FontAwesome";

        // TODO - Genericise
        .subtitle {
            font-family: $font-family-Lato-Regular;
            letter-spacing: -0.01em;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            $profileImageHeightDesktop: 100px;
            $profileImageHeightMobile: 100px;
            width: 100%;

            li {
                margin-bottom: 1.3em;
                position: relative;

                & a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    text-decoration: none;
                    z-index: 10;
                    background-color: #fff;
                    opacity: 0;
                    filter: alpha(opacity=1);
                }

                @include media-breakpoint-up(lg) {
                    float: left;
                    width: 28%;
                    margin-right: 5.6%;
                    margin-bottom: 1.8%;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }

                min-height: $profileImageHeightDesktop;
                background-color: $spire-light-blue;

                img {
                    margin: 0 0.8rem 0 0;
                    width: $profileImageHeightMobile;

                    @include media-breakpoint-up(md) {
                        margin: 0 1.2rem 0 0;
                        width: $profileImageHeightDesktop;
                    }

                    float: left;
                }

                $textMarginDesktopRight: 1.3rem;

                h2 {
                    margin-right: $textMarginDesktopRight;
                    color: $spire-blue;
                    font-family: $font-family-Lato-Regular;
                    font-size: 18px;
                    display: block;
                    padding-top: 0.6rem;

                    @include media-breakpoint-up(md) {
                        font-size: 20px;
                    }

                    span {
                        padding: 1em 0;
                    }
                }

                p {
                    font-family: $font-family-lato-regular;
                    font-size: 15px;
                    color: $spire-body-grey;
                    clear: left;
                    margin: 0.9em 1.9em 1.4em 1.1em;
                    line-height: 1.54em;

                    @include media-breakpoint-up(md) {
                        font-size: 1rem;
                        margin: 0;
                        margin-right: $textMarginDesktopRight;
                        line-height: 1.44em;
                        clear: none;
                        //padding-left: calc($profileImageHeight + 1rem);
                    }
                }

                .profile-link {
                    font-family: $font-family-Lato-Regular;
                    clear: left;
                    background-color: $spire-blue;
                    padding: 0.4em 0.8em;
                    text-align: right;

                    a {
                        color: white;

                        &::after {
                            font-family: $font-awesome;
                            display: inline-block;
                            margin-left: 0.6em;
                            content: $long-arrow-right;
                            //font-weight: $font-awesome-font-weight;
                        }
                    }
                }
            }
        }
    }
}
