// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 {
    line-height: 1.2;
    letter-spacing: -0.4px;
    font-family: $h1-font-family-mobile;
    font-size: $h1-font-size-mobile;

    @include media-breakpoint-up(md) {
        font-family: $h1-font-family;
        font-size: $h1-font-size;
    }
}
h2, .h2 {
    color: $spire-charcoal;
    //color: $spire-blue;
    line-height: 1.28;
    font-family: $h2-font-family-mobile;
    font-size: $h2-font-size-mobile;

    @include media-breakpoint-up(md) {
        line-height: 1.45;
        font-family: $h2-font-family;
        font-size: $h2-font-size;
    }

    & span {
        font-family: $h3-font-family;
    }

    &.blue {
        color: $spire-blue;
    }

}
h3, .h3 {
    line-height: 1.31;
    color: $spire-blue;
    font-family: $h3-font-family-mobile;
    font-size: $h3-font-size-mobile;
    @include media-breakpoint-up(md) {
        font-family: $h3-font-family;
        font-size: $h3-font-size;
    }
}
h4, .h4 {
    line-height: 1.31;
    font-family: $h4-font-family-mobile;
    font-size: $h4-font-size-mobile;

    @include media-breakpoint-up(md) {
        font-family: $h4-font-family;
        font-size: $h4-font-size;
    }
}
h5, .h5 {
    line-height: 1.31;
    font-family: $h5-font-family-mobile;
    font-size: $h5-font-size-mobile;

    @include media-breakpoint-up(md) {
        font-family: $h5-font-family;
        font-size: $h5-font-size;
    }
}
h6, .h6 {
    line-height: 1.31;
    font-family: $h6-font-family-mobile;
    font-size: $h6-font-size-mobile;

    @include media-breakpoint-up(md) {
        font-family: $h6-font-family;
        font-size: $h6-font-size;
    }
}

.first-word {
    font-family: 'Lato-Light';
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px $spire-charcoal;
}

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}

// Type display classes
/*.display-1 {
  font-size: $display1-size;
  font-weight: $display1-weight;
  line-height: $display-line-height;
}
.display-2 {
  font-size: $display2-size;
  font-weight: $display2-weight;
  line-height: $display-line-height;
}
.display-3 {
  font-size: $display3-size;
  font-weight: $display3-weight;
  line-height: $display-line-height;
}
.display-4 {
  font-size: $display4-size;
  font-weight: $display4-weight;
  line-height: $display-line-height;
}*/

.x-large {
    color: $spire-dark-grey;
    font-family: $font-family-lato-light;
    font-size: 28px;
    line-height: 1.28;
    letter-spacing: -0.25px;
    @include media-breakpoint-up(md) {
        font-size: 44px;
        line-height: 1.27;
        letter-spacing: -0.4px;
    }
}

.large {
    color: $spire-dark-grey;
    font-family: $font-family-lato-light;
    font-size: 24px;
    line-height: 1.16;
    letter-spacing: -0.8px;

    @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 1.22;
    }
}

.quote {
    color: $spire-dark-grey;
    font-family: $font-family-lato-light;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: -0.25px;

    @include media-breakpoint-up(md) {
        font-size: 28px;
        line-height: 1.28;
    }
}

.medium {
    color: $spire-blue;
    //font-family: $font-family-spire-light;
    font-family: $font-family-lato-regular;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.22px;

    @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 1.3;
    }
}

.medium-less {
    color: $spire-blue;
    //font-family: $font-family-spire-light;
    font-family: $font-family-lato-regular;
    font-size: 16px;
    letter-spacing: -0.22px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 19px;
    }
}

.medium-less-bold {
    color: $spire-blue;
    //font-family: $font-family-spire-light;
    font-family: $font-family-Lato-Regular;
    font-size: 16px;
    letter-spacing: -0.22px;

    @include media-breakpoint-up(md) {
        font-size: 17px;
        line-height: 19px;
    }
}

.medium-bold {
    color: $spire-blue;
    font-family: $font-family-spire-bold;
    font-size: 20px;
    line-height: 1.3;

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 1.33;
    }
}

.intro {
    color: $spire-dark-grey;
    font-family: $font-family-spire-light;
    font-size: 18px;
    line-height: 1.4;
    font-style: italic;

    @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 1.4;
    }
}

.emphasised {
    color: $spire-green;
    font-size: 18px;
}

.error {
    color: $spire-error;
    font-size: 14px;
}

//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  font-size: $blockquote-font-size;
}

.blockquote-footer {
  display: block;
  font-size: 80%; // back to default font-size
  color: $blockquote-small-color;

  &::before {
    content: "\2014 \00A0"; // em dash, nbsp
  }
}


// Colours


.white-header {
    color: #fff;

    > span {
        border-bottom-color: #fff;
    }
}