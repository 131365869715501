﻿
.panel-heading {
    color: #fff;
    background-color: #18839B;
    // border-bottom: 1px solid #fff;
    position: relative;
    padding: 13px;
    font-size: 0.87em;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    font-family: "Lato-light";
    font-size: 16px;

    h3 {
        font-family: "Lato-light";
        font-size: 28px;
       // opacity: 0.6;
        color: white;
        cursor: pointer;
    }

    p{
        font-family:"Lato-Regular";
        font-size:16px;
    }
}

.panel-title {
    position: relative;
    color: #fff;

   
}

.panel-title::after {
    content: "\f107";
    color: #fff;
    top: -2px;
    right: 0px;
    position: absolute;
    font-family: "FontAwesome"
}

.panel-title[aria-expanded="true"]::after {
    content: "\f106";
}

.panel-body {
    font-family: "Lato-Regular";
    font-size: 16px;
    color: white;
    margin-bottom: 10px;
    margin-left: 10px;

    @include media-breakpoint-up(md) {
      //  font-size: 19px;
    }

    a{
        color:#fff;
        font-weight:bold;
    }
}
