﻿
.testimonial {

    & .body {

        & .testimonial--standfirst {
            font-family: 'Lato-Regular';
            font-size: 20px;
            color: $spire-blue;

            &:before {
                font-family: fontawesomesolid;
                font-size: 45px;
                color: $spire-blue;
                content: "\f10d";
                position: relative;
                bottom: 0px;
                padding-right: 10px;
            }

            &:after {
                font-family: fontawesomesolid;
                font-size: 45px;
                color: $spire-blue;
                content: "\f10e";
                position: absolute;
                bottom: -25px;
                padding-left: 10px;
            }
        }

        & .subTitle {
            font-family: 'Lato-Regular';
            font-size: 16px;
            margin-top: 25px;
            margin-bottom: 16px;
        }
    }

    & .related {
        & ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            margin-bottom: 30px;
        }

        & .title {
            font-family: 'Lato-Regular';
        }

        &--social {
            color: $spire-blue;
            font-size: 20px;
            padding-right: 22px;
            margin-bottom: 16px;
        }
    }


    &--list {

        &--results {
            margin-left: 0;

            & .testimonial {
                padding-bottom: 35px;
                border-bottom: solid 1px $spire-medium-blue;
                margin-bottom: 35px;
                margin-left: 0;
                margin-right: 0;

                & .headerText {
                    font-family: 'Lato-Regular';
                    font-size: 20px;
                    color: $spire-blue;

                    &:before {
                        font-family: fontawesomesolid;
                        font-size: 45px;
                        color: $spire-blue;
                        content: "\f10d";
                        position: relative;
                        bottom: 0px;
                        padding-right: 10px;
                    }

                    &:after {
                        font-family: fontawesomesolid;
                        font-size: 45px;
                        color: $spire-blue;
                        content: "\f10e";
                        position: absolute;
                        bottom: -25px;
                        padding-left: 10px;
                    }
                }

                & .subTitle {
                    font-family: 'Lato-Regular';
                    font-size: 16px;
                    margin-top: 25px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}
