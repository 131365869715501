﻿.treatmentoverview {
    h3 {
        color: $spire-charcoal;
        margin-bottom: 1.3em;
    }

    .input-checkbox-wrapper {
        @include customCheckBox($spire-blue, $spire-light-blue, 15px, $spire-blue, null, 1.2em, 35%, 0.16em, 1.1em, $marginBottom: 0.4em);
    }

    &__filters {
        font-size: 15px;
        margin-bottom: 2em;

        a.show-filters-btn {
            color: $spire-blue;
            font-family: $font-family-Lato-Regular;

            &:hover {
                color: $spire-blue;
            }

            @include media-breakpoint-up(md) {
                display: none;
            }

            &::after {
                content: "+";
                display: inline-block;
                margin-left: 1em;
                margin-bottom: 1em;
            }
        }

        fieldset {
            .clear-btn {
                margin-top: 1em;
            }
            &.collapse {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                }

                &.show {
                    display: block;
                }
            }
        }
    }

    &__results {

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        > ul {
            @include media-breakpoint-up(md) {
                columns: 2;
                column-gap: 5.95rem;
                max-width: 93%;
            }

            > li {
                min-width: 100%;
                display: inline-block;
                margin-bottom: 2.5em;

                h3 {
                    margin-bottom: 0.3em;
                }

                &.hidden {
                    display: none;
                }

                ul {
                    li {
                        margin-bottom: 0.4em;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
