
.site-content {


    &--bg {
        background: url('/dist/images/backgrounds/BgShapes.png');
        background-repeat: no-repeat;
		background-size: 100%;
		
		&.site-content--treatmentareasaz {
			background-image: url('/dist/images/backgrounds/treatmentareasaz.png');
		}

		&.site-content--treatment {
			background-image: url('/dist/images/backgrounds/treatment.png');
		}

		&.site-content--scan {
			background-image: url('/dist/images/backgrounds/BgShapes_Reduced.png');
		}

		&.site-content--condition {
			background-image: url('/dist/images/backgrounds/BgShapes_Reduced.png');
		}

		&.site-content--symptom {
			background-image: url('/dist/images/backgrounds/BgShapes_Reduced.png');
		}
	}
	

}


body{
	//limiting viewport width for devices that don't support media queries (mobile first)
    color: $spire-body-grey;

	max-width: $mobile-max;
	@media (min-width: $tablet-min) {
		max-width: none;
	}
}

p {
    font-family: $font-family-lato-regular;
    //font-size: 16px;
    //line-height: 19px;    
}

::-ms-clear {
   display: none;
}

.grid img{
	max-width: 100%; 
}

.inner-container-fullwidth {
    background-color: $spire-mint;
    padding: 30px 0 30px 0;
}

.inner-area {
    margin: 0 auto;
    padding: 0 10px;
	max-width: 1020px;

    @media (min-width: $desktop-min) {
		padding: 0 20px;
		
		
	}
	
	h2 {
		margin-top: 0;
	}

	.inner-area--disable & {
		padding: 0;
	}
}

body {

    background: #fff;
}

.site-content {
	//background: $spire-mint;
	position: relative;
	// padding-bottom: 30px;

	&.is-hidden {
        display: none;
	}
	
	a {
        /*color: $spire-blue;*/

		&:hover {
			text-decoration: underline;			
			div, h3, p {
				text-decoration: underline;
			} 
		}
	}
}


.site-content{	

	a {
		&:hover {
			text-decoration: underline;			
			div, h3, p {
				text-decoration: underline;
			} 
		}
	}
}



.visible-gt-desktop{
	display: none;
	@media (min-width: $desktop-min) {
        &.grid__cell{
            display: inline-block;
        }
		display: block;
	}
}

//Common site elements



.button-spacer {
    margin-bottom: 4px;
}

.line-spacer {
    margin-bottom: 12px;
}

#sitetitle {
    font-size: 10px;
    position: absolute;
    top: 0;
    color: #fff;

    @media (min-width: $desktop-min) {
        font-size: 16px;
    }
}

.background--mint {
    background: $spire-mint;
}

.background--green {
	background: $spire-green;
	h4, h3, h2, p, .inner-area {
		color: #fff;
	}
}

.background--teal {
	background: $spire-teal;
	h4, h3, h2, p, .inner-area {
		color: #fff;
	}
}

.background--smoke {
    background: $spire-smoke;
}

.background--charcoal {
	background: $spire-charcoal;
	h4, h3, h2, p {
		color: #fff;
	}
}

.background--white {
    background: #fff;
}

.component-wrapper {
	padding-top: 20px;
	padding-bottom: 20px;

	&--background-white {
		background-color: #fff !important;
	}

	&--background-blue {
		background-color: $spire-blue !important;
	}

	&--background-light-blue {
		background-color: $spire-light-blue !important;
	}

	&--background-dark-blue {
		background-color: $spire-dark-blue !important;
	}

	&--no-bottom-margin {
		margin-bottom: 0;
	}

	&--no-top-margin {
		margin-top: 0;
	}
}

.inpage-notification {
	background-color: $spire-light-blue;
	border: solid 1px $spire-blue;
	padding: 20px;

	> p {
		margin-bottom: 0px;
	}

	& h2 {
		color: $spire-blue;
		font-size: 28px;
	}
}

