﻿.videoplaylist {
    background-color: $spire-light-grey;

    & h2 {
        color: $spire-blue;
    }

    & h3 {
        font-size: 20px;
        font-weight: bold;
    }

    & .video-description {
        display: none;
    }

    & .playing-video-panel {
        width: 100%;
    }

    & .playing-video-panel {
        margin-bottom: 20px;

        & .video-container {
            overflow: hidden;
            position: relative;
            width: 100%;

            &::after {
                padding-top: 56.25%;
                display: block;
                content: '';
            }

            & iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        & .video-description {

            & h4 {
                font-weight: bold;
            }

            & .consultant-list {
                margin-bottom: 10px;
            }
        }
    }

    & .video-list-item {
        padding-bottom: 30px;

        & a::after {
            font-family: FontAwesome;
            content: "\f178";
            padding-left: 5px;
            font-weight: normal;
        }

        & .video-length {
            margin-left: 10px;
            color: $spire-blue;

            & i {
                margin-right: 10px;
            }

            & .playing-indicator {
                color: #000;
                font-weight: bold;
            }
        }

        & .playing-indicator {
            color: $spire-grey;
            display: none;
        }
    }
}
