﻿.conditionslist {
    & h2 {
        color: $spire-blue;
    }

    & ul {
        list-style: none;

        li {
            font-weight: bold;
            padding-bottom: 5px;
        }

        li::before {
            content: "• ";
            color: $spire-blue;
        }

        @media (min-width: $desktop-min) {
            columns: 2;
        }
    }
}
