﻿.accordionlist {
    & .collapse {
        display: none;
    }

    & .show {
        display: block;
    }

    & .card {
        margin: 0;
        padding: 0;
        margin-bottom: 11px;
    }

    &--header {
        & a {
            font-family: "lato-regular";
            font-size: 22px;
            display: block;
            background-color: $spire-light-blue;
            border: solid 1px $spire-blue;
            color: $spire-blue;
            padding: 10px 0 10px 20px;
        }

        & a:hover {
            text-decoration: none !important;
        }

        & a:after {
            font-family: 'FontAwesome';
            position: absolute;
            right: 38px;
            font-size: 22px;
            content: "\f078";
            color: $spire-blue;
        }

        & .showup:after {
            content: "\f077";
        }
    }

    &--content {
        margin-top: 10px;
    }
}
