﻿
.article {

    h2 {
        color: $spire-blue;
    }

    & .date {
        border: solid 1px $spire-mid-grey;
        width: 70px;
        margin-bottom: 8px;

        &--header {
            background-color: $spire-blue;
            color: #fff;
            text-align: center;
        }

        &--body {
            background-color: $spire-smoke;
            color: $spire-blue;
            text-align: center;
            font-family: 'TheSans Spire Light';

            &--day {
                font-family: 'Lato-Regular';
                font-size: 28px;
                line-height: 28px;
                padding-top: 4px;
            }

            &--year {
                font-size: 16px;
            }
        }
    }

    & .body {
        margin-bottom: 16px;

        @include media-breakpoint-up(md) {
            padding-left: 25px;
        }

        &--standfirst {
            font-family: 'Lato-Regular';
            padding-bottom: 16px;
        }

        &--type {
            font-family: 'Lato-Regular';
            font-size: 16px;
        }
    }

    & .related {
        & ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            margin-bottom: 30px;
        }

        & .title {
            font-family: 'Lato-Regular';
        }

        &--social {
            color: $spire-blue;
            font-size: 20px;
            padding-right: 22px;
            margin-bottom: 16px;
        }
    }

    &--list {

        &--results {
            margin-left: 0;

            & .article {

                padding-bottom: 35px;
                border-bottom: solid 1px $spire-medium-blue;
                margin-bottom: 35px;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &--filter {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px $spire-blue;
    }
}
