﻿.sectiontext {
    & h2 {
        color: $spire-blue;
    }

    img {
        max-width: 100%;
    }

    .twoColumns {
        @media (min-width: $desktop-min) {
            columns: 2;
        }
    }

    & ul {
        list-style: none;
        padding: 0;

        li::before {
            content: "• ";
            color: $spire-blue;
        }
    }
}

.shaded-bg
{
    background-color: $spire-light-blue;
}