﻿#component-video-carousel {

    .slick-next {
        right: 5px;
    }

    .slick-dots li button:before {
        font-size: 36px;
    }

    .slick-dots {
        width: auto;
    }

    h2 {
        color: $spire-blue;
        font-size: 28px;
    }

    & .video-component--text--content {
        margin-top: 20px;
        min-height: 60px;

        p {
            color: $spire-blue;
            font-size: 16px;
        }

        &--link:after {
            font-family: FontAwesome;
            content: "\f178";
            padding-left: 5px;
        }

        &--link:hover {
            cursor: pointer;
        }
    }

    & .video-component--image {
        img {
            width: 100%;
        }
    }
}
