﻿.multidownloadblock {
    -webkit-box-shadow: 0 0 28px 0 rgba(0,0,0,0.20);
    -moz-box-shadow: 0 0 28px 0 rgba(0,0,0,0.20);
    box-shadow: 0 0 28px 0 rgba(0,0,0,0.20);
    padding: 30px;

    & h2 {
        color: $spire-blue;
    }

    & i {
        color: $spire-blue;
    }
}
