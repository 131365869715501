.wrapper-component {
    margin: auto;
    @include clearfix;

    @include media-breakpoint-up(md) {
        padding: $component-padding--desktop-top 0;
    }



    &.wrapper-component--first-item {
        padding-top: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            padding-top: 44px;
            margin-bottom: 44px;
        }
    }

    &--no-padding {
        padding: 0px !important;

        &.wrapper-component--first-item {
            padding-top: 0 !important;
        }
    }

    &--margin-on-mobile {
        margin-top: 62px;

        @include media-breakpoint-up(md) {
            margin-top: unset;
        }
    }
}

.container-component {

    &--spacing {
        padding-top: $component-padding--top;
        padding-bottom: $component-padding--bottom;

        @include media-breakpoint-up(md) {
            padding-top: $component-padding--desktop-top;
            padding-bottom: $component-padding--desktop-bottom;
        }
    }

    &--offset-image {

        width: 90%;
        margin: auto;
        margin-top: -35px;

        @include media-breakpoint-up(md) {
            margin-top: -65px;

        }

        margin-bottom: 30px;
    }
}

a {
    .fa-ml {
        margin-left: 5px;
    }

    .fa-mr {
        margin-right: 5px;
    }
}

.component-items {
    min-height: 650px;
}

