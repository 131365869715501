﻿.button {
    font-family: 'Lato-Regular';
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 9px;
    display: inline-block;

    &--rounded-back-blue-transparent {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: solid 2px $spire-blue;
        padding: 10px 20px 10px 20px;
        color: $spire-blue;

        .fa {
            font-family: fontawesomebrands;
            color: $spire-blue;
            padding-right: 10px;
        }
    }

    &--rounded-back-blue-transparent:before {
        font-family: 'FontAwesome';
        content: "\f177";
        margin-right: 5px;
    }

    &--rounded-back-blue-transparent:hover {
        text-decoration: none !important;
        background-color: $spire-blue;
        color: #fff;

        .fa {
            font-family: fontawesomebrands;
            color: #fff;
        }
    }


    &--rounded-blue-transparent {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: solid 2px $spire-blue;
        padding: 10px 20px 10px 20px;
        color: $spire-blue;

        .fa {
            font-family: fontawesomebrands;
            color: $spire-blue;
            padding-right: 10px;
        }
    }

    &--rounded-blue-transparent:after {
        font-family: 'FontAwesome';
        content: "\f178";
        padding-left: 5px;
    }

    &--rounded-blue-transparent:hover {
        text-decoration: none !important;
        background-color: $spire-blue;
        color: #fff;

        .fa {
            font-family: fontawesomebrands;
            color: #fff;
        }
    }

    &--rounded-filled-blue-transparent {
        background-color: $spire-blue;
        background-repeat: no-repeat;
        border: solid 2px $spire-blue;
        padding: 10px 20px 10px 20px;
        color: #fff;
    }

    &--rounded-filled-blue-transparent:after {
        font-family: 'FontAwesome';
        content: "\f178";
        padding-left: 5px;
    }

    &--rounded-filled-blue-transparent:hover {
        text-decoration: none !important;
        background-color: Transparent;
        color: $spire-blue;
    }

    &--rounded-white-transparent {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: solid 2px #fff;
        padding: 10px 20px 10px 20px;
        color: #fff;
    }

    &--rounded-white-transparent:after {
        font-family: 'FontAwesome';
        content: "\f178";
        padding-left: 5px;
    }

    &--rounded-white-transparent:hover {
        text-decoration: none !important;
        background-color: #fff;
        color: $spire-blue;
    }

    &--small-button {
        padding: 5px 10px 5px 10px !important;
    }

    &--very-small-button {
        padding: 5px 10px 5px 10px !important;
        font-size: 10px;
    }

    &--header {
        margin-top: 20px;
    }

    &--right-aligned-rounded-blue-filled {
        padding: 11px 31px !important;
        border-radius: 0px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        background-color: $spire-blue;
        color: #fff;

        &:hover {
            background-color: #FFFFFF;
            border: 1px solid $spire-blue;
            color: $spire-blue !important;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;

            .full-width-text & {
                background-color: #FFFFFF;
                color: $spire-blue;
            }
        }
    }

    &--rounded-blue-filled {
        padding: 10px 20px 10px 20px;
        border-radius: 9px;
        background-color: $spire-blue;
        color: #fff;
        border: solid 1px $spire-blue;

        &:hover {
            background-color: #FFFFFF;
            border: 1px solid $spire-blue;
            color: $spire-blue !important;

            .full-width-text & {
                background-color: #FFFFFF;
                color: $spire-blue;
            }
        }
    }
    
    &--green-rounded {
        padding: 10px 20px 10px 20px;
        border-radius: 9px;
        background-color: $spire-green;
        color: #fff;
        border: solid 1px $spire-green;

        &:hover {
            background-color: #FFFFFF;
            border: 1px solid $spire-green;
            color: $spire-green !important;

            .full-width-text & {
                background-color: #FFFFFF;
                color: $spire-green;
            }
        }
    }

    &--rounded-blue-filled:after {
        font-family: 'FontAwesome';
        content: "\f178";
        padding-left: 5px;
    }

    &--disabled {
        background-color: #878787 !important;
        color: #fff !important;
        border: solid 1px #878787 !important;
        cursor: not-allowed;
    }

    &--disabled:hover {
        color: #fff !important;
    }
}

.link {
    font-family: 'Lato-Regular';
    cursor: pointer;
    overflow: hidden;
    border-radius: 9px;
    display: inline-block;
    color: $spire-blue;

    &--white {
        color: #fff;

        &:after {
            font-family: 'FontAwesome';
            content: "\f178";
            padding-left: 5px;
        }
    }

}

.input-checkbox-wrapper {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.input-checkbox-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px $spire-blue;
}
/* On mouse-over, add a grey background color */
.input-checkbox-wrapper:hover input ~ .checkmark {
    background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.input-checkbox-wrapper input:checked ~ .checkmark {
    background-color: $spire-green;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.input-checkbox-wrapper input:checked ~ .checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
.input-checkbox-wrapper .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
