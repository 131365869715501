﻿.hospitalconsultantsintro {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 50px;

    .heading {
        color: $spire-charcoal;
        font-weight: bold;
        border-bottom: solid 2px $spire-body-light-grey;
        padding-bottom: 4px;
        margin-bottom: 8px;
    }


    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-family: 'Lato-Regular';

        > li {
            line-height: 18px;
            padding-bottom: 5px;

            > a {
                color: $spire-blue;
            }

            > .selected {
                color: $spire-body-grey;

                &::before {
                    font-family: FontAwesome;
                    content: $long-arrow-right;
                    padding-right: 4px;
                }
            }
        }

        > .heading {
            color: $spire-charcoal;
            font-weight: bold;
        }
    }

    #specialities ul {
        columns: 2;

        > li {
            padding-bottom: 5px;
        }

        @include media-breakpoint-up(md) {
            columns: 1;
        }
    }

    &--consultants-list {
        > ul {
            columns: 2;
            font-family: 'Lato-Regular' !important;

            > li {
                > a {
                    color: $spire-body-grey;
                }

                > .selected {
                    color: $spire-blue;
                }
            }
        }
    }

    &--profiles {

        .profile-image {
            float: left;
            padding: 3px;

            & img {
                width: 57px;
            }
        }

        & .selected {
            background-color: $spire-blue;
        }

        .profile-image:hover {
            cursor: pointer;
        }
    }

    &--consultants {
        padding-top: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 30px;
        }
    }

    &--view-profile {
        display: none;
        background-color: #fff;
        margin-top: 20px;

        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }

        & ul {
            list-style-type: disc;
            padding: 15px;
        }


        & .secretary-box {
            background-color: $spire-light-blue;
            border: solid 2px $spire-blue;
            margin-bottom: 10px;
            //padding: 10.5px 20px 10.5px 10px;
            @include media-breakpoint-up(md) {
                border: 0;
                padding: 10.5px 20px 10.5px 10px;
            }

            & .row {
            }

            &--header {
                color: $spire-blue;
                padding: 10px;
                font-weight: bold;

                @include media-breakpoint-up(md) {
                    padding: 0px;
                }
            }

            &--content {
                padding: 10px;

                & a {
                    color: $spire-blue;
                }

                & a:after {
                    text-decoration: none !important;
                }

                @include media-breakpoint-up(md) {
                    padding: 0px;
                }
            }

            &--contact {
                background-color: $spire-blue;
                text-align: right;
                padding-right: 20px;

                & a {
                    color: #fff;
                }

                @include media-breakpoint-up(md) {
                    background-color: $spire-light-blue;
                    text-align: left;

                    & a {
                        color: $spire-blue;
                    }
                }

                & a:after {
                    font-family: fontawesome;
                    content: "\f178";
                    margin-left: 5px;
                    text-decoration: none !important;
                }
            }

            .tel {
                font-family: 'Lato-Regular';
            }
        }
    }
}
