﻿.navigation-bar {

    &--module {
        display: none;
    }

    &--mobile {

        & ul {
            list-style-type: none;
            padding: 0;
        }

        & .logo {
            background: white;
            position: absolute;
            left: 20px;
            top: 20px;
            z-index: 999;
            width: 9.3rem;
            padding: 1.5rem 0.4rem;

            img {
                display: block;
                width: 100%;
            }
        }

        & .nav-bar-mobile {
            font-family: $font-family-spire-bold;
            font-size: 105%;
            background: $spire-header-blue;
            //padding: 1.5rem 0.7em 1em;
            text-align: left;
            right: 0;
            width: 100%;
            position: relative;
            top: 0;

            & li {
                border-bottom: solid 1px $spire-header-line;
                //padding: 0.5em 0;
                padding: 0.66em 0;
                display: block;

                & a {
                    color: white;
                }
            }

            & .has-children {
                position: relative;

                & .subnav--label {
                    display: inline-block;
                    width: 87% !important;
                    padding: 0.5em 0;
                }

                & .subnav--link {
                    padding: 5px 10px;
                    display: inline-block;
                    transition: transform 0.3s ease-out;
                    transform: rotate(180deg);

                    &.collapsed {
                        transform: rotate(0deg);
                    }
                }

                & li {
                    border-bottom: none;
                }
            }

            & .subnav--item {
                display: inline-block;
                width: 100%;
                padding: 0.5em 0;
            }

            &--menu {
                background-color: $spire-blue;
                padding: 1.5rem 0.7em 1em
            }

            &--header-menu {
                padding: 1.5rem 0.7em 1em;

                li {
                    border: none;

                    .tel {
                        i {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    &--desktop {
        & .logo {
            background-color: #fff;
            position: absolute;
            top: -15px;
            z-index: 1;

            & a {
                max-width: 260px;
                padding: 42px 17px;
                display: block;
            }
        }
        /** New **/
        //& .container {
        // width: 100%;
        // height: auto;
        // position: relative;
        //}
        & .shc-nav {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            color: $white;

            > li {
                display: block;
                flex-grow: 1;
                border-right: solid 1px $spire-body-light-grey;
                position: relative;

                > ul {
                    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
                    z-index: 999;
                    background-color: $spire-blue;
                    position: absolute;
                    top: 65px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 110%;
                    height: auto;
                    // Uncomment to hide menus again
                    opacity: 0;
                    visibility: hidden;
                    list-style: none;
                    //padding: 0px;
                    //border-bottom: 3px solid #ccc;
                    padding: 28px 27px;
                    font-size: 13px;
                }

                &.full-width {
                    position: static;

                    > ul {
                        width: 100%;
                        left: 0px;
                        transform: none;
                    }
                }

                & .active {
                    background: $spire-blue;
                }

                &:hover {
                    background: $spire-blue;

                    ul {
                        display: block;
                        opacity: 1;
                        visibility: visible;
                    }

                    a {
                        color: $white;
                    }

                    a.button:hover {
                        color: $spire-blue;
                    }
                }

                > a {
                    display: block;
                    text-align: center;
                    font-size: 15px;
                    text-decoration: none;
                    color: grey;
                    //line-height: 40px;
                    padding: 22px 14px;
                    //padding: 0px 19px;
                    //letter-spacing: .05em;
                    cursor: pointer;
                    position: relative;
                    color: $spire-blue
                }

                > a.active {
                    color: $white
                }

                > .dropdown {
                    &:after {
                        font-family: fontawesome;
                        content: "\f078";
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }


        & .shc-nav > .show > ul {
            display: block;
            opacity: 1;
            visibility: visible;
            position: absolute;
            right: 0;
        }
        /** End New **/
        & .consultant-menu {
            display: block;
            //padding: 28px 27px;
            padding: 20px 0px;

            h3 {
                color: white;
                font-size: 20px;
            }

            .defaultText {
                padding-right: 20px;
            }

            > li {
                > .container {
                    padding: 0;
                }
            }

            &--consultants {

                &--list {
                    -webkit-columns: 3;

                    ul {
                        list-style: none;
                        margin: 0 auto;
                        padding: 0;
                        margin-bottom: 10px;
                        font-size: 10px;

                        @include media-breakpoint-up(xl) {
                            font-size: 13px;
                        }
                    }

                    li {
                        a {
                            color: $spire-body-grey;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .active {
                            color: $spire-blue;
                        }
                    }
                }

                &--button {
                    display: block;
                }

                padding-right: 5px;
            }

            &--profile {
                border-left: solid 2px $spire-body-light-grey;
                padding-left: 30px;
                min-height: 180px;
                font-size: 14px;

                > .hide {
                    display: none;
                }

                > .show {
                    display: block;
                }

                & img {
                    width: 77px;
                }

                > .container {
                    padding: 0;

                    > .row {
                        > :not(:first-child) {
                            padding-left: 1em;
                        }
                    }
                }

                .consultant-name-filter-menu {
                    &__autocomplete {
                        position: absolute;
                        top: -20px;
                        left: 0;
                        right: 0;
                        z-index: 12;

                        &__warning, &__prompt, &__results {
                            background: #fff;
                            border-top: 1px solid #ccc;
                            padding: 10px;
                            @include box-sizing(border-box);
                            border-left: 1px solid $spire-teal;
                            border-right: 1px solid $spire-teal;
                            border-bottom: 1px solid $spire-teal;
                            width: 210px;
                            display: none;
                            margin-top: 2px;

                            &.is-on {
                                display: block;
                            }
                        }

                        &__warning, &__prompt {
                            color: #ccc;
                        }

                        &__results {
                            padding: 0;
                            padding-top: 20px;
                            padding-bottom: 20px;

                            ul {
                                @include list-reset;
                            }

                            li {
                                margin: 0;
                            }

                            a {
                                padding: 5px 10px;
                                color: inherit;
                                display: block;
                                color: $spire-blue !important;
                            }
                        }
                    }
                }

                #consultant-name-filter-menu {
                    border-style: none;
                    border-radius: 5px;
                    box-sizing: border-box;
                    color: #333;
                    padding: 16px 20px 17px 21px;
                    width: 100%;
                    border: none;
                    position: relative;
                    -webkit-appearance: none;
                    font-family: $font-family-lato-light;
                }

                #consultant-name-filter-menu-div {
                    position: relative;
                    margin-bottom: 20px;
                }

                #consultant-name-filter-menu-div:after {
                    font-family: icomoon2;
                    content: "\e986";
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    color: #18839b;
                    z-index: 1;
                    font-size: 15px;
                }
            }
        }


        & .treatments-menu {
            font-size: 17px;

            &--header {
                padding-top: 10px;

                & a {
                    color: $spire-blue;
                }
            }

            &--treatments {
                columns: 3;
                --webkit-column: 3;

                &--list {
                    font-size: 13px;
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 10px;
                    display: inline-block !important;
                    min-width: 51%;

                    & .heading {
                        color: $white;
                        font-weight: bold;
                        font-size: 14px;
                    }

                    & a {
                        color: $spire-body-grey;

                        &:hover {
                            color: $white;
                            text-decoration: underline;
                        }
                    }

                    & .active {
                        color: $spire-blue;
                        text-decoration: none;
                    }
                }
            }

            &--footer {
                padding-bottom: 10px;
                display: flex;
                text-align: center;
                justify-content: center;
            }
        }
    }
}
