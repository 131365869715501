//Sprite

//@import "icons/*.png";
//$icons-sprite-dimensions: true;

//Font


%icon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@function iconglyph($glyph){
	@if $glyph == email {
		@return "\e600";
	}
	@if $glyph == chat {
		@return "\e601";
	}
	@if $glyph == phone {
		@return "\e602";
	}
	@if $glyph == map {
		@return "\e603";
	}
	@if $glyph == newwindow {
		@return "\e604";
	}
	@if $glyph == formcorrect {
		@return "\e605";
	}
	@if $glyph == formerror {
		@return "\e606";
	}
	@if $glyph == logo {
		@return "\e607";
	}
	@if $glyph == menuclose {
		@return "\e608";
	}
	@if $glyph == menu {
		@return "\e609";
	}
	@if $glyph == search {
		@return "\e60a";
	}
	@if $glyph == ctaback {
		@return "\e60b";
	}
	@if $glyph == ctaclose {
		@return "\e60c";
	}
	@if $glyph == ctacontract {
		@return "\e60d";
	}
	@if $glyph == ctadownload {
		@return "\e60e";
	}
	@if $glyph == ctadropdownclose {
		@return "\e60f";
	}
	@if $glyph == ctadropdown {
		@return "\e610";
	}
	@if $glyph == ctaexpand {
		@return "\e611";
	}
	@if $glyph == ctaplay {
		@return "\e612";
	}
	@if $glyph == dropdownclose {
		@return "\e613";
	}
	@if $glyph == dropdown {
		@return "\e614";
	}
	@if $glyph == leftarrow {
		@return "\e615";
	}
	@if $glyph == rightarrow {
		@return "\e616";
	}
	@if $glyph == facebook {
		@return "\e617";
	}
    @if $glyph == exclamation {
        @return "\f12a";
    } 
	@if $glyph == twitter {
		@return "\e618";
	}
	@if $glyph == accordianclose {
		/*@return "\e619";*/
        @return "\f067";
	}
	@if $glyph == accordioncontract {
		/*@return "\e61a";*/
        @return "\f068";
	}
	@if $glyph == accordionexpand {
		/*@return "\e61b";*/
        @return "\f067";
	}
    @if $glyph == arrowup {
        //@return "\e618";
        @return "\ea3a";
    }
    @if $glyph == arrowdown  {
        @return "\ea3e";
    }
    @if $glyph == longarrow {
        @return "\f178";
    }
    @else {
        @return "";
    }
}

@mixin iconfont($glyph, $position: before, $fontname: 'icomoon') {

    @if $position == before {
        &:before {
            content: iconglyph($glyph);
            font-family: $fontname;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    @else {
        &:after {
            content: iconglyph($glyph);
            font-family: $fontname;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}

.icon-email:before {
	@extend %icon;
	content: "\e600";
}
.icon-chat:before {
	@extend %icon;
	content: "\e601";
}
.icon-phone:before {
	@extend %icon;
	content: "\e602";
}
.icon-map:before {
	@extend %icon;
	content: "\e603";
}
.icon-newscreen:before {
	@extend %icon;
	content: "\e604";
}
.icon-formcorrect:before {
	@extend %icon;
	content: "\e605";
}
.icon-formerror:before {
	@extend %icon;
	content: "\e606";
}
.icon-logo:before {
	@extend %icon;
	content: "\e607";
}
.icon-menuclose:before {
	@extend %icon;
	content: "\e608";
}
.icon-menu:before {
	@extend %icon;
	content: "\e609";
}
.icon-search:before {
	@extend %icon;
	content: "\e60a";
}
.icon-ctaback:before {
	@extend %icon;
	content: "\e60b";
}
.icon-ctaclose:before {
	@extend %icon;
	content: "\e60c";
}
.icon-ctacontract:before {
	@extend %icon;
	content: "\e60d";
}
.icon-ctadownload:before {
	@extend %icon;
	content: "\e60e";
}
.icon-ctadropdownclose:before {
	@extend %icon;
	content: "\e60f";
}
.icon-ctadropdown:before {
	@extend %icon;
	content: "\e610";
}
.icon-ctaexpand:before {
	@extend %icon;
	content: "\e611";
}
.icon-ctaplay:before {
	@extend %icon;
	content: "\e612";
}
.icon-dropdownclose:before {
	@extend %icon;
	content: "\e613";
}
.icon-dropdown:before {
	@extend %icon;
	content: "\e614";
}
.icon-leftarrow:before {
	@extend %icon;
	content: "\e615";
}
.icon-rightarrow:before {
	@extend %icon;
	content: "\e616";
}
.icon-facebook:before {
	@extend %icon;
	content: "\e617";
}
.icon-twitter:before {
	@extend %icon;
	content: "\e618";
}
.icon-accordianclose:before {
    @extend %icon;
    /*content: "\e619";*/
    content: '\f00e';
}
.icon-accordiancontract:before {
    @extend %icon;
    /*content: "\e61a";*/
    content: '\f068';
}
.icon-accordianexpand:before {
    @extend %icon;
    /*content: "\e61b";*/
    content: '\f00e';
}
/*.icon-new-window:after {
	@extend %icon;
	content: "\e604";
	position: absolute;
	right: 10px;
}*/
.icon-video:after {
    @extend %icon;
    content: "\e912";
    position: absolute;
    right: 7px;
}

.inner-addon {
   
    // 
}

/* style icon */
.inner-addon .glyphicon {
 //   position: absolute;
 //   padding: 10px;
  //  pointer-events: none;
}

/* align icon */
.left-addon .glyphicon {
  //  left: 0px;
}

.right-addon .glyphicon {
   // right: 0px;
}