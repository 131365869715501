﻿
#modal-video {
    .modal-dialog {
        max-width: 800px;
        margin: 30px auto;
    }

    .modal-body {
        position: relative;
        padding: 0px;
    }

    .close {
        position: absolute;
        right: 0;
        top: -30px;
        z-index: 999;
        font-size: 2rem;
        font-weight: normal;
        color: #fff;
        opacity: 1;

        @include media-breakpoint-up(md) {
            right: -30px;
            top: 0;
        }
    }
}

.modal-video__link {
    display:inline-block;
}

.picture-only {
    padding: 13px 2px;

    @include media-breakpoint-up(md) {
        padding: 44px 40px;
    }
}

.play-button {
    margin: 0 auto;
    display: table;
    text-align: center;
    position: relative;
    //margin-bottom: 20px;
    margin-bottom: 0px;

    @include media-breakpoint-up(md) {
        //  right: -30px;
        top: 0;
        margin-bottom: 0px;
    }
    //img {
    /*@include media-breakpoint-up(md) {
            width: 430px;
        }*/
    //}
}

.component-promo-media--text-left {

    .order-2 {
        .play-button {
            display: block;
            width: 100%;
            right: 15%;
            //position: absolute;
            @include media-breakpoint-up(md) {
                right: inherit;
                position: inherit;
                margin-right: -40px;
            }
        }
    }
}

.component-promo-media--text-right {

    .order-1 {
        .play-button {
            //left: 15%;
            display: block;
            width: 100%;

            @include media-breakpoint-up(md) {
                left: inherit;
                position: inherit;
                //margin-right: -40px;
            }
        }
    }
}


.play-button img {
    display: block;
}

.play-button i {
    width: 94px;
    height: 60px;
    color: #fff;
    //background-color: $spire-teal;
    background: rgba(24, 131, 155, 0.8);
    //border-radius: 50px;
    border-radius: 9px;
    text-align: center;
    line-height: 60px;
    font-size: 25px;

    &:hover {
        background-color: $spire-blue;
    }
}

.play-button-icon {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

