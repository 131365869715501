﻿.customer-ratings {
    padding-top: 2.2em;

    .starRating {
        text-align: center;

        h2 {

            @include media-breakpoint-up(md) {
            }
        }

        &--star {
            display: inline-block;
            background-image: url("/dist/images/StarEmpty.png");
            height: 28px;
            width: 29px;
        }

        .checked {
            display: inline-block;
            background-image: url("/dist/images/StarFilled.png");
            height: 28px;
            width: 29px;
        }

        .star-ratings-sprite {
            background: url("/dist/images/StarRatingSprite.png") repeat-x;
            font-size: 0;
            height: 28px;
            line-height: 0;
            overflow: hidden;
            /*text-indent: -999em;*/
            width: 150px;
            margin: 0 auto;
        }

        .star-ratings-sprite-rating {
            background: url("/dist/images/StarRatingSprite.png") repeat-x;
            background-position: 0 100%;
            float: left;
            height: 28px;
            display: block;
        }

        .star-ratings-sprite {
            background: url("/dist/images/StarRatingSprite.png") repeat-x;
            font-size: 0;
            height: 28px;
            line-height: 0;
            overflow: hidden;
            /*text-indent: -999em;*/
            width: 150px;
            margin: 0 auto;
            margin-bottom: 10px;
        }

        .star-ratings-sprite-rating {
            background: url("/dist/images/StarRatingSprite.png") repeat-x;
            background-position: 0 100%;
            float: left;
            height: 28px;
            display: block;
        }
    }

    &--summary-chart {
        font-size: 16px;

        .starRating {
            text-align: left;

            &--star {
                display: inline-block;
                background-image: url("/dist/images/StarEmpty.png");
                background-repeat: no-repeat;
                background-size: contain;
                height: 14px;
                width: 14px;
            }

            .checked {
                display: inline-block;
                background-image: url("/dist/images/StarFilled.png");
                height: 14px;
                width: 14px;
            }
        }

        .bar {
            margin-top: 4px;
            background-color: #F9F9F9;
            width: 100%;
            height: 16px;
            margin-bottom: 9px;
        }

        .bar--inner {
            background-color: #00A4A6;
            height: 100%;
            width: 0%;
            border-radius: 4px;
        }

        .bar--percent {
            padding-left: 10px;
            text-align: right;
            width: 100%;
            display: block;
        }
    }

    &--list {

        &--filter {
            padding: 30px 0 30px 0;
            border-bottom: solid 1px #ccc;
        }

        &--results {
            display: block;
            width: 100%;

            .comment {
                padding: 30px 0 30px 0;
                border-bottom: solid 1px #ccc;

                .hospital {
                    font-weight: bold;
                    margin-bottom: 20px;
                    display: block;
                }

                .headline {
                    font-weight: bold;
                    padding-left: 10px;
                    position: relative;
                    top: -2px;
                }

                .reason {
                    margin-top: 20px;
                }

                .starRating {
                    text-align: left;
                    display: inline-block;

                    &--star {
                        display: inline-block;
                        background-image: url("/dist/images/StarEmpty.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 14px;
                        width: 14px;
                    }

                    .checked {
                        display: inline-block;
                        background-image: url("/dist/images/StarFilled.png");
                        height: 14px;
                        width: 14px;
                    }
                }
            }
        }
    }
}
