﻿.pdf-wrapper {

    position: relative;
    overflow: hidden;

    & .embed-cover {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 20px;
    }
}
