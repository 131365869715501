﻿.homePageVideo {
    &--videoSettings-desktop {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            width: 100%;
            line-height: 0px !important;
        }
    }
}
