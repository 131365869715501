﻿.finduspanel {

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    & .map-image {
        min-height: 224px;

        @media (min-width: $desktop-min) {
            min-height: 382px;
        }
    }

    & .hospital-image {
        min-height: 224px;

        @media (min-width: $desktop-min) {
            min-height: 382px;
        }
    }
    /*& img {
        width: 100%;
    }*/
    &__find-us {
        background-color: $spire-blue;

        &--text {
            color: #fff;
            padding: 24px 32px 29px 19px;

            @media (min-width: $desktop-min) {
                padding: 41px 58px 55px 75px;
            }
        }
    }

    &__facilities {
        &--text {
            padding: 24px 32px 29px 19px;

            @media (min-width: $desktop-min) {
                padding: 41px 58px 55px 75px;
            }
        }
    }

    .facilitiesuspanel-left {
        background-color: $spire-blue;
        color: #fff;

        & h2 {
            color: #fff;

            & span {
                color: #fff;
            }

            & .first-word {
                border-bottom: solid 1px #fff;
            }
        }
    }
}
