﻿/*.select-wrapper {
    position: relative;

    select::-ms-expand {
        display: none;
    }

    &:before {
        font-family: fontawesome;
        content: "\f107";
        position: absolute;
        right: 10px;
        top: 50%;
        margin: -15px 0 0 0;
        font-size: 20px;
        color: $spire-blue;
    }
}*/

.select-wrapper {
    @include styleable-select($inputPadding);
}

.select-wrapper--inline {
    @include styleable-select($inputPadding);
    border: solid 1px $spire-blue;
    width: 200px !important;
    display: inline-block !important;
}

.paginationjs {
    display: block;
    width: 100%;
    text-align: right;
    margin-top: 20px;

    ul {
        list-style: none;

        li {
            display: inline;
            border: solid 1px $spire-border-line;
            padding: 12px;
            font-size: 16px;

            a {
                color: $spire-blue;

                &:hover {
                    text-decoration: none;
                    color: #fff;
                }
            }

            &:hover {
                text-decoration: none;
                background-color: $spire-header-blue;
                color: #fff;
                cursor: pointer;

                a {
                    color: #fff;
                }
            }
        }

        .active {
            background-color: $spire-header-blue;

            a {
                color: #fff;
            }
        }

        .disabled {
            display: none;

            a {
                color: $spire-charcoal;

                &:hover {
                    text-decoration: none;
                }
            }

            &:hover {
                text-decoration: none;
                background-color: #fff;

                a {
                    color: $spire-charcoal;
                }
            }
        }
    }
}


@mixin styleable-select($inputPadding) {
    background: #fff;
    width: 100%;
    @include iconfont(dropdown, before);
    position: relative;

    &:before {
        position: absolute;
        right: 10px;
        top: 50%;
        margin: -10px 0 0 0;
        font-size: 20px;
        color: $spire-turquoise;
    }

    &__select {
        @include box-sizing(border-box);
        color: $spire-grey;
        padding: $inputPadding 30px $inputPadding $inputPadding;
        width: 100%;
        background: none;
        border: none;
        position: relative;
        @include appearance(none);

        &::-ms-expand {
            display: none;
        }

        &:focus {
            outline: 0;
        }
    }

    &.is-open {
        //For filter dropdowns
        &:before {
            content: iconglyph(dropdownclose);
        }
    }
}

@mixin setup-form-elements {
    $inputPadding: 8px;

    .field-validation-error {
        color: $spire-error;
        font-size: 0.86rem;
    }

    .element-container {
        &.required-field {
            label::after {
                display: inline-block;
                margin-left: 0.2em;
                content: "*";
                color: $spire-green;
            }
        }

        .element-inner {
            position: relative;
            @include iconfont(exclamation-circle, before, "Font Awesome 5 Pro");

            &::before {
                display: none;
                color: $spire-error;
                font-weight: 900;
                position: absolute;
                right: 0.3em;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        input,
        .select-wrapper,
        textarea {
            border: 1px solid $spire-green-tint;
            border-radius: 4px;
            box-sizing: border-box;
        }

        &.container-error {
            input,
            .select-wrapper,
            textarea {
                border: solid 1px $spire-error;
            }

            .element-inner {
                &::before {
                    display: block;
                }
            }
        }

        &.submitting {
            &::after {
                content: "";
                display: inline-block;
                background: url("/dist/images/spinner-trans.gif") 0 0 / contain no-repeat;
                width: 3em;
                height: 3em;
                vertical-align: middle;
            }
        }

        label {
            display: block;
            font-weight: bold;
        }

        input {
            width: 100%;
            padding: $inputPadding;

            &:focus {
                border: 1px solid $spire-green;
                outline: 0;
            }
        }

        .select-wrapper {
            @include styleable-select($inputPadding);
        }

        .input-checkbox-wrapper {
            @include customCheckBox;
        }
    }
}


.input-text, .select-wrapper__select, textarea {
    font-family: $font-family-lato-light;
    border: 1px solid $spire-blue;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    -webkit-appearance: none;
    height: 59px;
    padding-left: 30px;

    &:focus {
        border: 1px solid $spire-dark-blue;
        outline: 0;
    }
}


.ui-helper-hidden-accessible {
    opacity: 0;
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    padding: 4px 0;
    margin: 0 0 10px 28px;
    list-style: none;
    background-color: #ffffff;
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
}

li.ui-menu-item {
    padding: 4px 10px;
}

.ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #555555;
    white-space: nowrap;
    text-decoration: none;
}

.ui-state-hover, .ui-state-active {
    color: #ffffff;
    text-decoration: none;
    background-color: #0088cc;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    background-image: none;
}