﻿.consultant-overview {
    margin-top: 20px;

    $font-awesome: "FontAwesome";
    $font-awesome-font-weight: 900;

    // TODO the colour is not working here for the active flag.
    #filter-speciality-list>li>a:active {
        background-color: #18839b;
        color: #fff;
    }

    .subtitle {
        font-family: $font-family-Lato-Regular;
        letter-spacing: -0.01em;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    & .filter-bar {
        font-size: 14px;
        width: 100%;
        padding-top: 10px;
        border-top: solid 1px #ccc;
        border-bottom: solid 1px #ccc;
        margin-bottom: 10px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }

        .title {
            font-family: 'Lato-Bold';
            color: $spire-blue;
        }

        .filterTitle {
            font-family: 'Lato-Bold';
        }

        .filterItems {
            max-width: 500px;
            word-wrap: break-spaces;

            span {
                padding-right: 10px;
            }
        }

        li {
            display: inline-block;
            padding-right: 10px;
        }
    }

    &__filter {
        margin-bottom: 0.6rem;

        > div {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 2.6rem;
            padding-bottom: 1.2rem;
        }

        .subtitle {
            display: inline-block;
            margin-right: 1.1em;
        }

        label {
            display: block;
            margin-bottom: 0.8em;

            @include media-breakpoint-up(md) {
                display: inline;
                margin-bottom: 0.5rem;
            }

            color: $spire-blue;
            letter-spacing: -0.03em;
            margin-right: 0.4em;
        }

        .list-wrapper {
            background-color: $spire-light-blue;
            padding: 20px;
            color: $spire-blue;

            & li:hover {
                cursor: pointer;
            }

            ul {
                padding-top: 10px;
            }

            #consultant-name-filter-div {
                position: relative;
            }

            #consultant-name-filter-div:after {
                font-family: icomoon2;
                content: "\e986";
                position: absolute;
                right: 10px;
                top: 7px;
                color: $spire-blue;
                z-index: 1;
                font-size: 15px;
            }

            #consultant-name-filter {
                border-style: none;
                border-radius: 5px;
                padding: 5px;
                width: 100%;
            }

            #treatment-name-filter-div {
                position: relative;
                margin-top: 10px;
            }

            #treatment-name-filter-div:after {
                font-family: icomoon2;
                content: "\e986";
                position: absolute;
                right: 10px;
                top: 7px;
                color: $spire-blue;
                z-index: 1;
                font-size: 15px;
            }

            #treatment-name-filter {
                border-style: none;
                border-radius: 5px;
                padding: 5px;
                width: 100%;
            }
        }
    }

    &__profiles {
        margin-bottom: 80px;

        ul {
            $profileImageHeightDesktop: 60px;
            $profileImageHeightMobile: 60px;
            width: 100%;

            li {
                margin-bottom: 1.3em;
                clear: both;

                .treatment-header {
                    font-weight: bold;
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 1.8%;
                }

                &:nth-child(even) {
                    margin-right: 0;
                }

                img {
                    margin: 0 0.8rem 0 0;
                    width: $profileImageHeightMobile;

                    @include media-breakpoint-up(md) {
                        margin: 0 1.2rem 0 0;
                        width: $profileImageHeightDesktop;
                    }

                    float: left;
                }

                $textMarginDesktopRight: 1.3rem;

                h2 {
                    color: $spire-blue;
                    font-family: $font-family-Lato-Regular;
                    font-size: 18px;
                    display: table;
                    padding-top: 0;
                    margin-top: 0.6rem;
                    margin-bottom: 0.6rem;
                    min-height: $profileImageHeightMobile;
                    font-size: 20px;
                    min-height: $profileImageHeightDesktop;
                    display: block;
                    min-height: auto;

                    span {
                        padding: 0;
                        word-spacing: normal;
                        word-break: normal;
                        display: inline;
                        vertical-align: middle;
                        word-break: normal;
                    }
                }

                .consultant p {
                    font-family: $font-family-lato-regular;
                }

                .consultant {
                    clear: both;
                    margin-top: 20px;
                }

                p {
                    font-family: $font-family-Lato-Regular;
                    font-size: 15px;
                    color: $spire-body-grey;
                    clear: left;
                    margin: 0;
                    line-height: 1.54em;
                    clear: none;

                    @include media-breakpoint-up(md) {
                        font-size: 1rem;
                        margin: 0;
                        line-height: 1.44em;
                        //padding-left: calc($profileImageHeight + 1rem);
                    }
                }

                .profile-link {
                    font-family: $font-family-Lato-Regular;
                    clear: left;
                    background-color: $spire-blue;
                    padding: 0.4em 0.8em;
                    text-align: right;

                    a {
                        color: white;

                        &::after {
                            font-family: $font-awesome;
                            display: inline-block;
                            margin-left: 0.6em;
                            content: $long-arrow-right;
                            //font-weight: $font-awesome-font-weight;
                        }
                    }
                }
            }
        }
    }
}
