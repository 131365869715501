﻿.inpagecallout {
    border: 1px solid $spire-blue;
    background-color: $spire-light-blue;
    padding: 10px;
    text-align: center;
    font-weight: bold;

    & a:after {
        font-family: FontAwesome;
        content: "\f178";
        padding-left: 5px;
    }

    & .seperator {
        padding: 5px;
        color: $spire-blue;
        font-weight: bold;
    }
}
