﻿@function em($size, $context: $typeBase) {
    @return $size / $context * 1em;
}

@mixin box-sizing($style) {
    box-sizing: $style;
}

@mixin appearance($style) {
    -webkit-appearance: $style;
}

@mixin transition($style, $size: 0.2s) {
}

@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin opacity($size: 0.5) {
    opacity: $size;
}

@mixin input-placeholder {
}

@mixin background-image($style) {
}

//RGBA backgrounds with hex fallback;
@mixin background-rgba($color, $opacity) {
    background: $color;
    background: rgba($color, $opacity);
}

//REM sized fonts with px fallback
@mixin font-size-rem($size) {
    font-size: $size;
    font-size: $size / $typeBase * 1rem;
}

//Remove default styling from a list tag
@mixin list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

// Blanket overlay
@mixin coverer {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Rotation
@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin origin($val) {
    -webkit-transform-origin: $val;
    -moz-transform-origin: $val;
    -ms-transform-origin: $val;
    transform-origin: $val;
}
