@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-left-seperator($borderColor: $spire-medium-blue) {
    border-left: solid 1px $borderColor !important;
}
