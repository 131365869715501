﻿#component-hero-home-carousel {
    //  background: $spire-light-blue;
    .hero-home-carousel {
        // min-height: 10px;
        &.no-padding {
            padding: 0px !important;
        }

        &.mobile-view {
            display: block;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &.desktop-view {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        & .carousel__caption {
            height: 90px;
            opacity: 0.9;
            line-height: 1;
            background-color: #74B5C3;
            color: #fff;
            //  margin: 0 12px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 16px 20px;
            z-index: 1;

            @include media-breakpoint-up(md) {
                height: 50px;
                opacity: 0.9;
            }


            h4 {
                font-family: 'Lato-Regular';
                // font-size: 20px;
                @include media-breakpoint-up(md) {
                    height: 55px;
                  //  margin-left: 150px;
                    font-family: 'Lato-Regular';
                    font-size: 24px;
                }
            }

            a {
                color: white;
                font-family: "Lato-Regular";
                // font-size: 16px;
                float: left;

                @include media-breakpoint-up(md) {
                    margin: 5px 0px 0px 10px;
                }

                &:after {
                    font-family: FontAwesome;
                    content: "\f178";
                    padding-left: 5px;
                }
            }

            &--wrapper {
                //  padding-top: 40px;
            }
        }



        .slick-slider {
            margin-bottom: 0;
            margin-left: -12px;
            margin-right: -12px;

            @include media-breakpoint-up(md) {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        .slick-slide {
            outline: 0 !important;
        }


        .slick-prev {
            display: none !important;
            left: auto;
            bottom: 25px;

            @include media-breakpoint-up(md) {
                display: inline-block !important;
                //  border: 2px solid white;
                left: 100px !important;
            }
        }

        .slick-next {
            display: none !important;
            bottom: -18px;

            @include media-breakpoint-up(md) {
                display: inline-block !important;
                //    border: 2px solid white;
                bottom: 0px;
            }
        }

        .slick-arrow {
            top: auto;
            left: auto;
            right: 104px;
            z-index: 2;

            @include media-breakpoint-up(md) {
                top: 50%;
                left: auto;
                right: 131px;
                //border: 2px solid white;
            }

            &:before {
                font-size: 20px;

                @include media-breakpoint-up(md) {
                    font-size: 30px;
                    border: 2px solid white;
                }
            }
        }

        .slick-dots {
            bottom: 0px;
            left: 40%;
            width: 30%;
            margin-left: 0;
            margin-bottom: -85px;

            @include media-breakpoint-up(md) {
                margin-bottom: 5px;
                bottom: -70px;
                left: 70%;
            }

            li {
                width: 16px;
                margin-top: 4px;

                button {
                    border: 0;
                    background: $spire-blue;
                    border-radius: 20px;
                    display: block;
                    height: 8px;
                    width: 8px;

                    &:before {
                        content: "";
                    }
                }

                &.slick-active {
                    margin-top: 0;

                    button {
                        background: white;
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }
    }
}
