﻿.hero {
    padding: 0;

    &--image {
        background-color: $spire-blue;
        min-height: 200px;
        text-align: center;
        line-height: 200px;
        color: #fff
    }

    &__title {
        background-color: $spire-blue;
        padding: ($h2-font-size * 2) 0;
        text-align: center;
        color: white;
        font-family: $font-family-lato-light;

        h1 {
            font-size: 1.75rem;
            font-weight: $font-weight-bold;

            span:first-child {
                font-family: $font-family-lato-light;
                font-weight: $font-weight-light;
            }
        }
    }
}
