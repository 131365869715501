﻿.imagetextbanner {

    & h2 {
        color: $spire-blue;
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
    }

    &--image {
        padding-left: 0px;
        padding-right: 0px;

        img {
            width: 100%;
        }

        &--roundel {
            border-radius: 40px;
        }
    }

    &--text {
        background-color: $spire-light-blue;
        color: $spire-charcoal;

        &--roundel {
            background-color: white;
        }

        &--content {
            padding-left: 50px;
            padding-right: 50px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;

            @include media-breakpoint-up(md) {
                font-size: 15px;
            }

            & a {
                color: $spire-blue !important;
                font-weight: bold;
            }

            & a:hover {
                cursor: pointer;
            }

            & a:after {
                font-family: FontAwesome;
                content: "\f178";
                padding-left: 5px;
            }
        }
    }
}
