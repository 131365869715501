@mixin timelinetrack() { 
    width: 100%;
    height: 8px;
    background: $spire-mint;
    border: none;
}

@mixin timelinethumb() {
    -webkit-appearance: none;
        border: none;
        height: 8px;
        background: $spire-turquoise;

        .range-length--2 & {
            width: 50%;
        }

        .range-length--3 & {
            width: 33%;
        }

        .range-length--3 & {
            width: 25%;
        }
        
        .range-length--4 & {
            width: 20%;
        }

        .range-length--5 & {
            width: 17%;
        }

        .range-length--6 & {
            width: 15%;
        }

        .range-length--7 & {
            width: 13%;
        }

        .range-length--8 & {
            width: 12%;
        }

        .range-length--9 & {
            width: 10%;
        }

        .range-length--10 & {
            width: 9%;
        }

        .range-length--11 & {
            width: 8.3%;
        }

        .range-length--12 & {
            width: 7.70%;
        }
}

@mixin customCheckBox($checkmarkBorderColor: $spire-turquoise, $checkmarkBackgroundColor: white, $fontSize: null, $labelColor: null, $font-weight: null, $checkboxWidth: 1.5em, $tickSize: 23%, $tickWeight: 0.19em, $checkboxHeight: $checkboxWidth, $marginBottom: 0.75em) {
    display: block;
    position: relative;
    padding-left: 1.45 * $checkboxWidth;
    margin-bottom: $marginBottom;
    cursor: pointer;
    font-size: $fontSize;
    font-weight: $font-weight;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $labelColor;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: auto;
    }
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: $checkboxHeight;
        width: $checkboxWidth;
        background-color: $checkmarkBackgroundColor;
        border-radius: 4px;
        border: solid 1px $checkmarkBorderColor;
        /* Create the checkmark/indicator (hidden when not checked) */
        &::after {
            content: "";
            position: absolute;
            display: none;
        }
    }
    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #fff;
    }
    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: $spire-green;
    }
    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }
    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 52%;
        top: 41%;
        width: $tickSize;
        height: $tickSize * 2;
        border: solid #fff;
        border-width: 0 $tickWeight $tickWeight 0;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
}

//@mixin styleable-select($borderColor, $display: block, $width: 100%, $padding: 1em 2.4em 1.3em 1.1em, $fontFamily: $font-family-Lato-Regular, $fontColor: $spire-charcoal, $icon-location: 0.5em, $iconFontFamily: 'Font Awesome 5 Pro', $iconFontWeight: 900) {
@mixin styleable-select($borderColor, $display: block, $width: 100%, $padding: 16px 20px 17px 21px, $fontFamily: $font-family-lato-light, $fontColor: $spire-charcoal, $icon-location: 0.5em, $iconFontFamily: 'Font Awesome 5 Pro', $iconFontWeight: 900) {
    font-family: $fontFamily;
    display: $display;
    background: #fff;
    width: $width;
    position: relative;
    border: 1px solid $borderColor;
    border-radius: 4px;
    box-sizing: border-box;

    &:before {
        font-family: $iconFontFamily;
        font-weight: $iconFontWeight;
        content: "\f107";
        position: absolute;
        right: $icon-location;
        top: 50%;
        margin: -10px 0 0 0;
        font-size: 20px;
        color: $spire-blue;
    }

    &__select {
        box-sizing: border-box;
        color: $fontColor;
        padding: $padding;
        width: 100%;
        background: none;
        border: none;
        position: relative;
        -webkit-appearance: none;

        &::-ms-expand {
            display: none;
        }

        &:focus {
            outline: 0;
        }
    }

    &.is-open {
        //For filter dropdowns
        &:before {
            //content: iconglyph(dropdownclose);
            font-family: $iconFontFamily;
            content: "\e107";
        }
    }
}