.component-promo-media {
    &--comp-background {
        background-color: #F0FAFA;
    }

    &.has-transcript {
        .transcript {
            &__text {
                max-height: 0;
                overflow: hidden;
                margin-top: 1em;
                transition: max-height 0.3s;

                > div {
                    margin-bottom: 1em;
                }
            }

            .view-transcript {
                display: inline-block;
                margin-top: 1em;
            }

            .hide-transcript {
                display: none;
            }

            &.show {
                .transcript__text {
                    max-height: 1000px;
                    transition: max-height 1s;
                }

                &.done {
                    .transcript__text {
                        overflow: auto;
                    }
                }

                .hide-transcript {
                    display: inline-block;
                    margin-top: 1em;
                }

                .view-transcript {
                    display: none;
                }
            }
        }
    }
}
