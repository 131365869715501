﻿.simplecalloutpanel {

    & h2 {
        color: $spire-blue;
    }

    .spire-turquoise {
        color: $spire-turquoise;
    }

    .spire-light-turquoise {
        color: $spire-light-turquoise;
    }

    .spire-grey {
        color: $spire-grey;
    }

    .copy-text > ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .copy-text > ul > li::before {
        content: "• ";
        color: $spire-blue;
    }

    .full-width-image-panel {
        position: relative;
        color: white;
        font-style: italic;

        &--copy-text--left {
            @media (min-width: $desktop-min) {
                left: 50px;
            }
        }

        &--copy-text--right {
            @media (min-width: $desktop-min) {
                right: 50px;
            }
        }

        &--copy-text {
            background-color: $spire-header-blue;

            @media (min-width: $desktop-min) {
                width: 40%;
                position: absolute;
                top: 40px;
                }
            padding: 20px;

            > p {
                font-size: 28px;
                font-weight: normal;
            }
        }
    }

    &--image {
        margin: 0;
        padding: 0;
        z-index: -1;
        position: relative;

        img {
            width: 100%;
        }
    }

    &--image-text {

        &--left {
            @media (min-width: $desktop-min) {
                margin-top: 15%;
                margin-right: -20%;
            }
        }

        &--right {
            @media (min-width: $desktop-min) {
                margin-top: 15%;
                margin-left: -20%;
            }
        }

        background-color: $spire-light-blue;
        padding: 20px;

        > p {
            font-size: 28px;
            font-weight: normal;
        }
    }

    &--fullimage-text {

        &--left {
            @media (min-width: $desktop-min) {
            }
        }

        &--right {
            @media (min-width: $desktop-min) {
            }
        }

        background-color: $spire-light-blue;
        padding: 20px;

        > p {
            font-size: 28px;
            font-weight: normal;
        }
    }


    &--text {

        h2 {
            font-family: 'Lato-Light';
            color: $spire-charcoal;
        }

        background-color: $spire-light-blue;
        color: $spire-charcoal;

        &--content {
            padding-left: 50px;
            padding-right: 50px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;

            @include media-breakpoint-up(md) {
                font-size: 15px;
            }
        }
    }
}
