﻿.bodymapcontainer {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;

    & h3 {
        color: #000;
    }

    svg {
        width: 100%;
    }

    .lite {
        font-family: Lato-Light;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    .loading {
        background-image: url(/dist/images/Spinner-1s-200px.gif);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 999999;
    }

    .select-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;

        @media (min-width: $desktop-min) {
            display: none;
        }
    }
}
