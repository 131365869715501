﻿.site-header {
    &--contact-bar {
        display: block;
        background-color: $spire-header-blue;
        text-align: right;
        color: #fff;
        display: none;
        padding: 5px 0;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &--wrapper {
            background-color: $spire-header-blue;
        }

        a {
            color: #fff;
        }

        & .toplink a {
            color: #fff;
            font-size: 13px;

            & span {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        & .toplink a:hover {
            text-decoration: underline;
        }

        & .tel a {
            font-size: 13px;
            color: #fff;

            & span {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        & .tel a:hover {
            text-decoration: underline;
        }

        & .enquiry a {
            font-size: 13px;
            color: #fff;

            & span {
                padding-left: 10px;
                padding-right: 10px;
            }

            &:after {
                font-family: FontAwesome;
                content: "\f178";
                padding-left: 5px;
            }
        }

        & .logout a {
            font-size: 13px;
            color: #fff;

            & span {
                padding-left: 10px;
                padding-right: 10px;
            }

            &:after {
                font-family: FontAwesome;
                content: "\f178";
                padding-left: 5px;
            }
        }

        & .menu {
            & i {
                color: #fff;
            }
        }

        & a:hover {
            text-decoration: none;
        }

        &--mobile {
            display: block;
            font-size: 16px;
            //padding: 16px 0;
            @include media-breakpoint-up(lg) {
                display: none;
            }

            .contact-link {
                display: inline;

                &::before {
                    //font: 100 16px fontawesome;
                }

                & a {
                    font-family: Lato-Regular;
                }

                aside {
                    background-color: $spire-header-blue;
                    right: 0;
                    display: none;
                    position: absolute;
                    top: 100%;
                    padding: 0.3em 1em 0.6em;
                    z-index: 1;
                }

                &:hover {
                    aside {
                        display: block;
                    }
                }
            }

            & .toplink a {
                color: #fff;
                font-size: 16px;
            }

            & .tel a {
                color: #fff;
                font-size: 16px;
                //margin-right: 30px;
            }

            & .enquiry {
                display: inline-block;
                padding: 16px 0 16px 30px;
            }

            & .enquiry a {
                color: #fff;
                font-size: 16px;
                //margin-right: 30px;
                &:after {
                    content: "";
                    padding-left: 0px;
                }
            }

            & .logout {
                display: inline-block;
                padding: 16px 0 16px 30px;
            }

            & .navbar-toggler {
                padding: 0;
                line-height: 0;
                outline: none;
            }

            & .menu {
                padding-left: 30px;
            }

            & .menu i {
                color: #fff;
                font-size: 16px;
            }
        }
    }
}

.call-to-action {
    //color: red;
    position: fixed;
    z-index: 9;
    right: 0;
    top: 50%;
    //background-color: $spire-grey;
    background-color: $spire-teal;
    color: #fff;
    padding: 13px 15px 0 15px;
    width: 172px;
    margin: -180px 0 0 0;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);

    &__clickToCall {
        height: 53px;
        position: fixed;
        bottom: 6px;
        z-index: 999;
        background: #fff;
        text-align: center;
        right: 0;
        left: 15px;
        padding-right: 0px !important;
        padding-left: 2px;
        padding-right: 2px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 5px 0 0 5px;
        border: solid 1px $spire-light-grey;

        a {
            width: 100%;
            margin-left: 1px;
            margin-right: 1px;
            text-align: center;
            /*padding-left: 50px;
            display: block;
            background-color: $spire-grey;
            border: none;
            text-align: center;
            border-radius: 10px;
            color: #fff;*/
        }

        a:visited, a:hover {
            color: #fff;
        }

        &.is-hidden {
            display: none;
        }
    }
}

.call-to-action__clickToCall a {
    width: 100%;
    text-align: center;
}

.mobile-home-cta {
    @media (min-width: $desktop-min) {
        display: none;
    }
}