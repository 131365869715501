﻿
.component-wrapper--background-dark-white {

    .slick-prev:before {
        background: #18839B;
    }

    .slick-next:before {
        background: #18839B;
    }
}

.component-wrapper--background-dark-blue {

    .slick-dots li.slick-active button {
        background: #fff !important;
    }

    .slick-dots li button {
        background: #fff !important;
    }

    h2, a, p, .emphasised {
        color: #fff !important;
    }

    .slick-prev:before {
        background: #E7F2F5;
    }

    .slick-next:before {
        background: #E7F2F5;
    }
}

.component-wrapper--background-light-blue {

    .slick-prev:before {
        background: #18839B;
    }

    .slick-next:before {
        background: #18839B;
    }
}


#component-home-carousel {
    background: $spire-light-blue;
    padding-bottom: 50px;
    /*@include media-breakpoint-up(md) {
        padding-bottom: 0;
    }*/



    .home-carousel {
        min-height: 10px;
        /*@include media-breakpoint-up(md) {
            min-height: 600px;
        }*/
        & .carousel__caption {
            background-color: $spire-header-blue;
            color: #fff;
            margin: 0 12px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 16px 20px;
            z-index: 1;
        }

        &--wrapper {
            padding-top: 40px;
        }

        .component-wrapper--background-dark-blue, .component-wrapper--background-light-blue {
            background-color: unset !important;
        }
    }

    .slick-slider {
        margin-bottom: 0;
        margin-left: -12px;
        margin-right: -12px;

        @include media-breakpoint-up(md) {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .slick-slide {
        outline: 0 !important;
    }


    .slick-prev {
        display: none !important;
        left: auto;
        bottom: 25px;

        @include media-breakpoint-up(md) {
            display: inline-block !important;
            left: -25px !important;
        }

        &:before {
            font-family: fontawesome;
            content: "\f177";
        }
    }

    .slick-next {
        display: none !important;
        bottom: -18px;


        @include media-breakpoint-up(md) {
            display: inline-block !important;
            bottom: 0px;
        }

        &:before {
            font-family: fontawesome;
            content: "\f178";
        }
    }

    .slick-arrow {
        top: auto;
        left: auto;
        right: 35px;
        z-index: 2;

        @include media-breakpoint-up(md) {
            top: 50%;
            left: auto;
            right: -25px;
        }

        &:before {
            font-size: 20px;

            @include media-breakpoint-up(md) {
                font-size: 30px;
            }
        }
    }
    /*.component-wrapper--background-white+.slick-next:before {
        background-color: black !important;
    }

    .slick-prev:before {
        background-color: black !important;
    }

    .component-wrapper--background-dark-blue .slick-prev:before, .component-wrapper--background-dark-blue .slick-next:before {
        background-color: red !important;
    }

    .component-wrapper--background-light-blue .slick-prev:before, .component-wrapper--background-light-blue .slick-next:before {
        background-color: blue !important;
    }

    .component-wrapper--background-white > .slick-prev:before, .component-wrapper--background-white .slick-next:before {
        background-color: black !important;
    }*/

    .slick-dots {
        bottom: 0px;
        left: 25%;
        width: 50%;
        margin-left: 0;
        margin-bottom: -30px;

        @include media-breakpoint-up(md) {
            margin-bottom: 5px;
            bottom: -30px;
        }

        li {
            width: 16px;
            margin-top: 4px;

            button {
                border: 0;
                background: $spire-body-grey;
                border-radius: 20px;
                display: block;
                height: 8px;
                width: 8px;

                &:before {
                    content: "";
                }
            }

            &.slick-active {
                margin-top: 0;

                button {
                    background: $spire-blue;
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }
}
