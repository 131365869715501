﻿.downloadslist {
    & h2 {
        color: $spire-blue;
    }

    & .download-item {
        border-bottom: 1px solid $spire-blue;
        padding-top: 10px;
        padding-bottom: 10px;

        &-description {
            max-height: 80px;
            position: relative;
            overflow: hidden;
        }

        & .read-more {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding-top: 50px;
            background-image: linear-gradient(to bottom, transparent, white);
        }

        & .read-less {
            display: none;
        }

        & .date-created {
            color: $spire-grey;
        }
    }

    & .file-details {
        text-align: center;
        width: 80px;
        cursor: pointer;

        @media (min-width: $desktop-min) {
            float: right;
            padding-top: 25px;
        }

        & img {
            width: 50px;
        }

        & .file-type {
            font-weight: bold;
            color: $spire-grey;
        }
    }

    & .show-more {
        padding-top: 20px;
        text-align: center;
    }

    & .downloads__filters {
        border-bottom: 1px solid $spire-blue;
        padding-bottom: 20px;

        & h3 {
            @media (min-width: $desktop-min) {
                float: left;
                padding-right: 10px;
                padding-top: 10px;
            }
        }

        & .select-wrapper__select {
            padding: 6px 20px 7px 21px;
        }
    }
}
