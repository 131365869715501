﻿.contact-us-banner {

    &--intro {
        //padding: 20px 24px;
        padding: 18px 16px 18px 0;

        & p {
            font-family: inherit;
        }
    }

    &--telephone {
        //padding: 20px 24px;
        padding: 18px 16px 18px 0;
        background-color: $spire-light-blue;
        font-family: 'Lato-Regular';

        label {
            display: block;
            color: $spire-blue;
            margin: 0;
            padding: 0;
        }

        & .telephoneLabel {
            font-size: 14px;
        }

        & .telephoneNumber {
            font-size: 30px;

            a {
                color: $spire-blue;

                &:hover {
                    color: $spire-blue;
                    text-decoration: none;
                }
            }
        }

        &--link {
            display: block;
            font-size: 16px;

            a {
                color: $spire-blue;

                &:hover {
                    color: $spire-blue;
                    text-decoration: none;
                }

                &:after {
                    font-family: FontAwesome;
                    content: "\f178";
                    padding-left: 5px;
                }
            }
        }
    }
}
