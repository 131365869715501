﻿.box-panel {
    padding-top: 20px;

    & h2 {
        color: $spire-blue;
    }

    &--content {
        &--boxes {
            .box {
                box-shadow: 0 3px 6px rgba(0,0,0,0.16);
                padding: 20px;
                margin-bottom: 20px;

                @media (min-width: $desktop-min) {
                    min-height: 100%;
                }
            }
        }
    }
}
