﻿body {
}
@mixin sanslight {
    font-family: 'Open Sans', sans-serif;
}

@mixin sans {
    font-family: 'Open Sans', sans-serif;
}

@font-face {
    font-family: 'Lato-Light';
    src: url('/dist/css/fonts/Lato2OFLWeb/Lato/fonts/Lato-Light.woff') format('woff');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('/dist/css/fonts/Lato2OFLWeb/Lato/fonts/Lato-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('/dist/css/fonts/Lato2OFLWeb/Lato/fonts/Lato-Bold.woff') format('woff');
}

@font-face {
    font-family: 'TheSans Spire Light';
    src: url('/dist/css/fonts/SpireFont/TSSpTT3_.ttf') format("truetype"), url("/dist/css/fonts/SpireFont/TSSpTT3_.eot") format("embedded-opentype");
}

@font-face {
    font-family: 'TheSans Spire Bold';
    src: url('/dist/css/fonts/SpireFont/TSSpTT7_.ttf') format("truetype"), url("/dist/css/fonts/SpireFont/TSSpTT7_.eot") format("embedded-opentype");
}

//https://icomoon.io/

@font-face {
    font-family: 'icomoon';
    src: url('/dist/css/fonts/icons/icomoon.eot?7ibya4');
    src: url('/dist/css/fonts/icons/icomoon.eot?#iefix7ibya4') format('embedded-opentype'), url('/dist/css/fonts/icons/icomoon.ttf?7ibya4') format('truetype'), url('/dist/css/fonts/icons/icomoon.woff?7ibya4') format('woff'), url('/dist/css/fonts/icomoon.svg?7ibya4#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon2';
    src: url('/dist/css/fonts/icons/icomoon2.eot?7ibya4');
    src: url('/dist/css/fonts/icons/icomoon2.eot?#iefix7ibya4') format('embedded-opentype'), url('/dist/css/fonts/icons/icomoon2.ttf?7ibya4') format('truetype'), url('/dist/css/fonts/icons/icomoon2.woff?7ibya4') format('woff'), url('/dist/css/fonts/icons/icomoon2.svg?7ibya4#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: fontawesome;
    src: url('/dist/css/fonts/FontAwesome5/webfonts/fa-regular-400.eot') format('embedded-opentype'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-regular-400.woff2') format('woff2'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-regular-400.woff') format('woff'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-regular-400.ttf') format('truetype'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-regular-400.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: fontawesomebrands;
    src: url('/dist/css/fonts/FontAwesome5/webfonts/fa-brands-400.eot') format('embedded-opentype'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-brands-400.woff2') format('woff2'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-brands-400.woff') format('woff'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-brands-400.ttf') format('truetype'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-brands-400.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: fontawesomesolid;
    src: url('/dist/css/fonts/FontAwesome5/webfonts/fa-solid-900.eot') format('embedded-opentype'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-solid-900.woff2') format('woff2'), url('/dist/css/fonts/FontAwesome5/webfonts/fa-solid-900.woff') format('woff'), url('/dist/css/fonts/FontAwesome5/webfonts/fas-regular-400.ttf') format('truetype'), url('/dist/css/fonts/FontAwesome5/webfonts/fas-regular-400.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}



/*@font-face {
    font-family: 'FontAwesome';
    src: url('/dist/css/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('/dist/css/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('/dist/css/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('/dist/css/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('/dist/css/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('/dist/css/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

/*.fa, .fas, .far, .fal, .fab {
    font-family: fontawesome;
}*/




