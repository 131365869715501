﻿.SecretaryContactDetails {
    
    padding-left: 0px;
    padding-right: 0px;

    & .header {
        font-size: 20px;
        color: $spire-blue;
        padding: 20px 0;
    }

    & .consultant, .email, .telephone, .secretary {
        word-break: break-word;
    }

    & .row-striped:nth-of-type(odd) {
        background-color: $spire-light-blue;
        padding: 26px 0;
    }

    & .row-striped:nth-of-type(even) {
        background-color: #ffffff;
        padding: 26px 0;
    }
}
