﻿.calloutpanel {
    & h2 {
        color: $spire-blue;
    }

    .spire-turquoise {
        color: $spire-turquoise;
    }

    .spire-light-turquoise {
        color: $spire-light-turquoise;
    }

    .spire-grey {
        color: $spire-grey;
    }

    .copy-text > ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .copy-text > ul > li::before {
        content: "• ";
        color: $spire-blue;
    }

    &--image {
        margin: 0;
        padding: 0;
        z-index: -1;
        position: relative;

        img {
            width: 90%;
        }
    }

    &--image-text {
        margin-top: -80px;
        margin-left: 10%;
        background-color: $spire-light-blue;
        padding: 20px;

        > p {
            font-size: 28px;
            font-weight: normal;
        }

        > a {
            font-weight: bold;
        }

        > a::after {
            font-family: FontAwesome;
            content: "\f178";
            padding-left: 5px;
            font-weight: normal;
        }
    }



    &--text {

        h2 {
            font-family: 'Lato-Light';
            color: $spire-charcoal;
        }

        background-color: $spire-light-blue;
        color: $spire-charcoal;

        &--content {
            padding-left: 50px;
            padding-right: 50px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;

            @include media-breakpoint-up(md) {
                font-size: 15px;
            }
        }
    }
}
