﻿.new-hero {

    & h2 {
        color: $spire-blue;
    }

    img {
        max-width: 100%;
    }

    .hero-content-header {
        padding-top: 76px;
    }

    .simple-hero {
        text-align: center;
        padding: 56px 0;
    }
    
    .hero-content-image {
        position: relative;
    }

    .hero-box {
        background-color: white;
        position: relative;
        margin-bottom: 20px;
    }

    .hero-box a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-decoration: none;
        z-index: 10;
        background-color: white;
        opacity: 0;
        filter: alpha(opacity=1);
    }

    .hero-box-text {
        padding: 30px;
        padding-bottom: 10px;
        min-height: 150px;
    }

    .hero-box-image img {
        width: 100%;
    }

    .hero-box-image {
        max-height: 250px;
        overflow-y: hidden;
    }

    .offset-background {
        height: 70%;
        position: absolute;
        width: 100%;
    }

    .hero {
        position: relative;
    }

    .link-url {
        text-decoration: underline;
        color: #18839b;
        padding: 0 30px 0 30px;
    }
}
