footer {
    display: block;
    bottom: 0;
    width: 100%;
}

.footer-bar {
    background-color: $spire-blue;
    color: #fff;

    & .top {
        padding-top: 22px;
        padding-bottom: 23px;
        background-color: $spire-dark-blue;
        font-size: 13px;

        & .contact {

            & a {
                color: #fff;
            }

            & ul {
                list-style-type: none;
                padding: 0;
                padding-top: 10px;

                @include media-breakpoint-up(md) {
                    padding-top: 0px;
                }
            }
        }

        & img {
            display: inline-block;
            width: 50%;
            padding-bottom: 20px;

            @include media-breakpoint-up(md) {
                width: 100%;
                max-width: 240px;
            }
        }

        & .tel {
            color: #fff;
            padding-right: 81px;

            & a {
                color: #fff;

                & i {
                    margin-right: 9px;
                }
            }
        }

        & .email {
            color: #fff;

            & a {
                color: #fff;

                & i {
                    margin-right: 9px;
                }
            }
        }

        & .twitter {
            color: #fff;
            //todo not working correctly.
            & a {
                color: #fff;

                & i {
                    margin-right: 9px;
                }

                & i:before {
                    font-family: fontawesomebrands;
                    content: \f099;
                }
            }
        }
    }

    & .bottom {
        font-size: 11px;
        padding-top: 6px;
        padding-bottom: 7px;

        & a {
            color: #fff;
        }

        .privacy-column {
            text-align-last: left;

            @include media-breakpoint-up(md) {
                text-align-last: right;
            }
        }

        & a:hover {
            text-decoration: underline;
        }
    }
}
