﻿.consultant-profile {
    padding-top: 2.2em;

    .headshot {
        margin-bottom: 20px;

        img {
            width: auto;
        }
    }

    & .initial-description {
        & .subtitle {
            display: inline-block;
            color: $spire-blue;
            line-height: 1.45;
            font-family: Lato-Regular;
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    .contact-details {
        background-color: $spire-blue;
        padding: 20px;
        padding-top: 5px;
        color: white;

        & a {
            color: white;
        }

        & .address-heading {
            font-size: 22px;
            padding-top: 15px;
        }

        &--desktop {

            & h3 {
                color: $spire-charcoal;
            }

            & .section {
                padding-top: 20px;
            }

            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        &--mobile {

            & h3 {
                color: $spire-charcoal;
                font-size: 18px;
            }

            & .section {
                padding-top: 20px;
            }

            display: block;
            margin-top: 20px;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    & .description {
        margin-bottom: 30px;
    }

    h3 {
        margin-top: 10px;
    }

    & .pricing {
        border: solid 1px $spire-blue;
        padding: 12px 46px 14px 13px;
        color: $spire-blue;
        background-color: $spire-light-blue;
        margin-bottom: 20px;
    }

    & table {
        width: 100%;
        border-collapse: collapse;
        color: $spire-body-grey;
        margin-bottom: 14px;
    }

    & tr {
        background-color: $spire-light-blue;
    }

    & tr, td {
        padding: 5px 5px 5px 10px;
    }

    & .spaceunder {
        border-right: hidden;
    }

    & .clinictimes {
        border-collapse: separate;
        border-spacing: 0 8px;
    }

    & .button {
        width: 100%;
        text-align: center;
    }

    & .spacing {
        margin-top: 20px;
    }

    & .specialises-in {
        ul {
            list-style: none;
        }

        ul li::before {
            content: "\2022";
            color: $spire_blue;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }

    & .treatments {
        ul {
            list-style: none;

            @media (min-width: $desktop-min) {
                column-count: 2;
            }
        }

        ul li::before {
            content: "\2022";
            color: $spire_grey;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
}
