﻿.twitterandtreatmentpanel {

    & .border-left {
        border-left: none !important;

        @media (min-width: $desktop-min) {
            @include border-left-seperator($spire-medium-blue);
        }
    }

    & .select-dropdown--container {

        & .select-wrapper {
            border: 1px solid $spire-blue;
            border-radius: 4px;
            //@include styleable-select($spire-blue);
            @include styleable-select($spire-blue, inline-block, 100%, 16px 20px 17px 21px, $font-family-lato-light, $spire-body-grey, 0.8em);
            margin-bottom: 34px;
        }
    }

    &__left {
        padding-right: 59px;

        @media (min-width: $desktop-min) {
            padding-right: 118px;
        }
    }

    &__right {
        padding-left: 0px;
        margin-top: 20px;

        @media (min-width: $tablet-min) {
            padding-left: 76px;
        }
    }
}
